import React, { useState, useContext } from "react";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import { Link, useHistory } from "react-router-dom";
import styled from "styled-components";
import JSCookie from "js-cookie";
import { AppContext } from "../contexts/AppContext";

const SidebarLink = styled(Link)`
  display: flex;
  color: #000000;
  justify-content: space-between;
  align-items: center;
  padding: 20px 14px;
  list-style: none;
  min-height: 40px;
  height: auto;
  text-decoration: none;
  font-size: 15px;
  font-style: normal;
  font-stretch: normal;
  text-overflow: ellipsis;
  font-weight: normal;
  &:hover {
    background: #f2923d;
    cursor: pointer;
    color: white;

    text-decoration: none;
  }
`;

const SidebarLabel = styled.span`
  margin-left: 16px;
`;

const DropdownLink = styled(Link)`
  background: #484545;
  height: 48px;
  padding-left: 3rem;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: white;
  font-size: 15px;

  &:hover {
    background: #d0d0d0;
    cursor: pointer;
    color: black;
    text-decoration: none;
  }
`;

const SubMenu = (props) => {
  // global state
  const history = useHistory();
  const { state, setState } = useContext(AppContext);
  const [subnav, setSubnav] = useState(true);
  const showSubnav = () => setSubnav(!subnav);

  return (
    <>
      <SidebarLink
        to={props.item.path}
        onClick={props.item.editions && showSubnav}
        style={{ backgroundColor: "rgb(242, 146, 61)", color: "white" }}
      >
        <div>
          {props.item.icon}
          <SidebarLabel>{props.item.publicationnameBengla}</SidebarLabel>
        </div>
        {/* </Link> */}
        <div>
          {props.item.editions && subnav
            ? props.item.iconOpened ?? <ArrowDropUpIcon />
            : props.item.editions
            ? props.item.iconClosed ?? <ArrowDropDownIcon />
            : null}
        </div>
      </SidebarLink>
      {subnav &&
        props.item.editions.map((edn, index) => {
          return (
            <Link
              to={`/${props.item?.publicationname
                ?.split(" ")
                .join("-")
                .toLowerCase()}/${edn?.editionname
                ?.split(" ")
                .join("-")
                .toLowerCase()}`}
              target="_self"
              style={{ color: "inherit" }}
            >
              <DropdownLink
                onClick={async (e) => {
                  await props.setLogoAndTheme(
                    props?.item.theme_color,
                    props?.item.logo
                  );
                  history.push(
                    `/${props.item?.publicationname
                      ?.split(" ")
                      .join("-")
                      .toLowerCase()}/${edn?.editionname
                      ?.split(" ")
                      .join("-")
                      .toLowerCase()}`
                  );
                  if (props.index != JSCookie.get("espubindex"))
                    JSCookie.set("espubindex", props?.index, { expires: 3 });
                  props.handleEdition(edn, index);
                  props.toggleDrawer(props?.anchor, !props.anchor);
                  setState({ state: false });
                }}
                key={index}
              >
                {edn.icon}
                <SidebarLabel>{edn.editionnameBeng}</SidebarLabel>
              </DropdownLink>
            </Link>
          );
        })}
    </>
  );
};

export default SubMenu;
