import HomePage from "../components/HomePage";
import Archive from "../components/Archive";
import DigestView from "../components/DigestView";
import ReplicaView from "../components/ReplicaView";
import GalleryView from "../components/GalleryView";
import AboutPage from "../components/AboutPage";
import AdvertisePage from "../components/AdvertisePage";
import TermsAndConditionsPage from "../components/TermsAndConditionsPage";
import PrivacyPolicyPage from "../components/PrivacyPolicyPage";
import Highlight from "../components/Highlight";
import Renew from "../components/LoginWizard/steps/Renew";
import ShareArticle from "../components/ShareArticle";
import Overview from "../components/Overview";
import Team from "../pages/Team";
import "driver.js/dist/driver.min.css";
import MyAccount from "../components/MyAccount";
import ArticleView from "../components/ArticleView";
import "intro.js/introjs.css";
import AdTesting from "../components/AdTesting";
import AdsText from "../components/AdsText";
import ArchiveCopy from "../components/ArchiveCopy";
import CookiesChoice from "../components/CookiesChoice";
import FaqPage from "../components/FaqPage";
import AboutEpaper from "../components/AboutEpaper";
import HomePageNbt from "../components/HomePageNbt";
import Jsso from "../components/Jsso";
const authProtectedRoutes = [
  { path: "/digest-view", exact: true, component: DigestView },
  { path: "/gallery-view", exact: true, component: GalleryView },
  { path: "/my-account", exact: true, component: MyAccount },
  { path: "/eisamay/archives", exact: true, component: ArchiveCopy },

  { path: "/:publication/:edition", exact: true, component: ReplicaView },
];
const publicRoutes = [
  { path: "/overview", exact: true, component: Overview },
  { path: "/eisamay/about", exact: true, component: AboutPage },
  { path: "/eisamay/faqpage", exact: true, component: FaqPage },
  { path: "/eisamay/about-epaper", exact: true, component: AboutEpaper },

  {
    path: "/highlight",
    exact: true,
    component: Highlight,
  },
  {
    path: "/eisamay/check-users-cookie",
    exact: true,
    component: CookiesChoice,
  },

  { path: "/advertise", exact: true, component: AdvertisePage },
  { path: "/eisamay/terms", exact: true, component: TermsAndConditionsPage },
  { path: "/eisamay/privacy", exact: true, component: PrivacyPolicyPage },
  { path: "/renew-subscription", exact: true, component: Renew },
  { path: "/team", exact: true, component: Team },
  { path: "/article-view", exact: true, component: ArticleView },
  { path: "/eisamay/home", exact: true, component: HomePageNbt },

  { path: "/eisamay/article-share", exact: true, component: ShareArticle },
  { path: "/ads", exact: true, component: AdTesting },
  { path: "/eisamay/jsso", exact: true, component: Jsso },
];
export { authProtectedRoutes, publicRoutes };
