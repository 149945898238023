import React, { useContext } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import { useHistory, Redirect } from "react-router-dom";
import HeightSharpIcon from "@material-ui/icons/HeightSharp";
import JSCookie from "js-cookie";
import List from "@material-ui/core/List";
import NotificationsNoneOutlinedIcon from "@material-ui/icons/NotificationsNoneOutlined";
import ZoomOutMapIcon from "@material-ui/icons/ZoomOutMap";
import { getCustomer } from "../helper";
import jsonp from "jsonp";
import axios from "axios";
import ListItem from "@material-ui/core/ListItem";
import QuestionAnswerIcon from "@material-ui/icons/QuestionAnswer";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import DehazeIcon from "@material-ui/icons/Dehaze";
import PhotoLibraryIcon from "@material-ui/icons/PhotoLibrary";
import ReceiptOutlinedIcon from "@material-ui/icons/ReceiptOutlined";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import HelpOutlineOutlinedIcon from "@material-ui/icons/HelpOutlineOutlined";
import PrintIcon from "@material-ui/icons/Print";
import ShareIcon from "@material-ui/icons/Share";
import BookmarkBorderIcon from "@material-ui/icons/BookmarkBorder";
import "../components/sidebar.css";
import clip_icon from "../assets/icons/clip_icon.png";
import CropIcon from "@material-ui/icons/Crop";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import fit_to_width_icon from "../assets/icons/fit_to_width.png";
import { Columns, Maximize } from "react-feather";
import Tooltip from "@material-ui/core/Tooltip";
import { LogOut, LogIn } from "react-feather";
import MailModal from "./MailModal";
import { Modal } from "reactstrap";

//context
import { AppContext } from "../contexts/AppContext";
import {
  displayEvents,
  loginEvents,
  myAccountEvent,
  signOutEvent,
} from "../functions/cleverTap";
const useStyles = makeStyles({
  list: {
    width: 250,
    backgroundColor: "white",
  },
  fullList: {
    width: "auto",
  },
  drawerPaper: {
    top: "0px",
    overflow: "auto",
  },
});

export default function RightSidebarMenu(props) {
  const classes = useStyles();
  // global state
  const {
    setIsFitToHeight,
    isPopularDrawerOpen,
    setWidth,
    refactorHighlight,
    vw,
    isPanoramaPage,
    setIsPopularDrawerOpen,
    setFit2page,
    ratioMainPage,
    fit2pagesArray,
    s3Source,
    fit2page,
    isOpenContactModal,
    setIsOpenContactModal,
  } = useContext(AppContext);
  const [state, setState] = React.useState({
    // top: false,
    // left: false,
    // bottom: false,
    right: false,
  });
  const [loggedOut, setLoggedOut] = React.useState(false);

  const history = useHistory();

  const handleLogout = () => {
    window.location.reload();
    let customer_details = localStorage.getItem("customer_details");
    if (customer_details) customer_details = JSON.parse(customer_details);
    axios
      .delete(
        `${process.env.REACT_APP_SUBSCRIPTION_URL}/customer/logout/${customer_details?.id}`
      )
      .then((res) => {
        console.log("logged out");
      });
    let prev_localstorage = localStorage.getItem("active_day");
    localStorage.clear();
    localStorage.setItem("active_day", prev_localstorage);
    JSCookie.remove("token");
    jsonp(
      `https://jsso.indiatimes.com/sso/identity/profile/logout/external?channel=${process.env.REACT_APP_CHANNEL_SSO_NAME}`,
      null,
      function async(err, data) {
        if (err) {
          console.error(err.message);
        } else {
        }
      }
    );
  };
  const handleLogin = () => {
    window.open(
      `https://jsso.indiatimes.com/sso/identity/login?channel=${process.env.REACT_APP_CHANNEL_SSO_NAME}&ru=${window.location.origin}/eisamay/jsso`,
      "Ratting",
      "width=600,height=600,toolbar=0,status=0,"
    );
  };
  const handleClick = () => history.push(`/gallery-view`);
  const handleClickReplica = () =>
    history.push(
      `/${props.publicationsData[JSCookie.get("espubindex")]?.publicationname
        ?.split(" ")
        .join("-")
        .toLowerCase()}/${props.publicationsData[
        JSCookie.get("espubindex")
      ]?.editions[JSCookie.get("esedindex")]?.editionname
        ?.split(" ")
        .join("-")
        .toLowerCase()}`
    );
  const handleClickDigest = () => history.push(`/digest-view`);
  const fullScreen = (param) => {
    if (param === "fscr") {
      var elem = document.documentElement;
      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      } else if (elem.webkitRequestFullscreen) {
        /* Safari */
        elem.webkitRequestFullscreen();
      } else if (elem.msRequestFullscreen) {
        /* IE11 */
        elem.msRequestFullscreen();
      }
    }
  };
  const handleProfile = () => {
    setTimeout(() => {
      window.location.reload();
    }, 200);
    history.push("/my-account");
  };
  const onFitToHeight = async () => {
    setIsFitToHeight((currState) => currState + 1);

    setWidth((currState) => {
      const targetHeight = window.innerHeight - 200;

      const newWidth = ratioMainPage * targetHeight;
      refactorHighlight(newWidth, currState);
      return newWidth;
    });
    if (fit2page) {
      await updatePage();
    }
    setFit2page(false);
  };
  const updatePage = () => {
    const page = props.dayIndexData.findIndex(
      (v) =>
        v.PageName ===
        fit2pagesArray[props.currentPage?.pageNumber - 1].first.PageName
    );
    props.pageClick(
      `${s3Source}/PublicationData/${props?.defaultInitialPubCode}/${props?.defaultInitialEditionName}/${props?.dateArray[0]}/${props?.dateArray[1]}/${props?.dateArray[2]}/PageThumb/${props.dayIndexData[page].PageName}.jpg`,
      page
    );
  };
  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === "top" || anchor === "bottom",
      })}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List className="text-uppercase  ">
        <ListItem
          button
          onClick={() => {
            myAccountEvent("my_account", "my_account", "my_account", "header");
            window.open("https://eisamaygold.timesgroup.com/myaccount");
          }}
        >
          <ListItemIcon>
            <AccountCircleIcon style={{ color: props.themeColor }} />
          </ListItemIcon>
          <ListItemText primary={"আমার অ্যাকাউন্ট"} />
        </ListItem>
        {/* <ListItem button>
          <ListItemIcon>
            <NotificationsNoneOutlinedIcon
              style={{ color: props.themeColor }}
            />
          </ListItemIcon>
          <ListItemText primary={"NOTIFICATIONS"} />
        </ListItem> */}
        {/* <ListItem button>
          <ListItemIcon>
            <BookmarkBorderIcon style={{ color: props.themeColor }} />
          </ListItemIcon>
          <ListItemText primary={"MY BOOKMARK"} />
        </ListItem> */}
      </List>
      <div className="bg-light">
        <h5
          className="font-weight-bold text-icon-color  pl-4 py-1"
          style={{ color: props.themeColor }}
        >
          প্রদর্শন
        </h5>
      </div>
      <List className="text-uppercase  ">
        <ListItem
          button
          onClick={async () => {
            displayEvents(
              "display_button",
              "display_button",
              "display_button",
              "fit_to_width"
            );
            setWidth((currState) => {
              let newWidth = null;
              let sidebarWidth = null;
              if (window.innerWidth > 2000) {
                sidebarWidth = isPopularDrawerOpen ? vw(18.4) : vw(0.7);
                newWidth = window.innerWidth - sidebarWidth;
              } else if (window.innerWidth > 1800) {
                sidebarWidth = isPopularDrawerOpen ? vw(18.2) : vw(0.7);
                newWidth = window.innerWidth - sidebarWidth;
              } else if (window.innerWidth < 1366) {
                newWidth = window.innerWidth;
              } else if (window.innerWidth > 1365) {
                const sidebarWidth = isPopularDrawerOpen ? 340 : vw(0.7);
                newWidth = window.innerWidth - sidebarWidth;
              }
              refactorHighlight(newWidth, currState);

              return newWidth;
            });
            setIsFitToHeight(0);
            setFit2page(false);

            if (fit2page) {
              await updatePage();
            }
          }}
        >
          <ListItemIcon>
            {/* <img src={fit_to_width_icon} height="24px" /> */}

            <ZoomOutMapIcon style={{ color: props.themeColor }} />
          </ListItemIcon>
          <ListItemText primary={"প্রস্থে মাপসই"} />
        </ListItem>
        <ListItem
          button
          onClick={() => {
            displayEvents(
              "display_button",
              "display_button",
              "display_button",
              "fit_to_height"
            );
            onFitToHeight();
          }}
        >
          <ListItemIcon>
            <HeightSharpIcon style={{ color: props.themeColor }} />
          </ListItemIcon>
          <ListItemText primary={"উচ্চতায় মানানসই"} />
        </ListItem>
        {window.innerWidth > 767 && (
          <ListItem
            onClick={() => {
              displayEvents(
                "display_button",
                "display_button",
                "display_button",
                "two_pages"
              );
              setWidth((currState) => {
                // const sidebarWidth = isPopularDrawerOpen
                //   ? vw(12)
                //   : vw(0.7);
                const newWidth = !isPanoramaPage
                  ? window.innerWidth / 2
                  : window.innerWidth - vw(0.7);
                // - sidebarWidth;
                refactorHighlight(newWidth, currState);
                return newWidth;
              });
              setIsFitToHeight(0);

              setIsPopularDrawerOpen(false);
              let page = fit2pagesArray.findIndex(
                (v) => v.first.PageName === props.currentPage?.PageName
              );
              if (page === -1) {
                page = fit2pagesArray.findIndex((v) => {
                  return v?.second?.PageName === props.currentPage?.PageName;
                });
              }
              if (page !== -1) {
                props.pageClick(
                  `${s3Source}/PublicationData/${props?.defaultInitialPubCode}/${props?.defaultInitialEditionName}/${props?.dateArray[0]}/${props?.dateArray[1]}/${props?.dateArray[2]}/PageThumb/${fit2pagesArray[page].PageName}.jpg`,
                  page
                );
              }
              setFit2page(true);
            }}
            button
          >
            <ListItemIcon>
              <Columns style={{ color: props.themeColor }} />
            </ListItemIcon>
            <ListItemText primary={"দুই পৃষ্ঠা"} />
          </ListItem>
        )}
        <ListItem
          button
          onClick={() => {
            fullScreen("fscr");
            displayEvents(
              "display_button",
              "display_button",
              "display_button",
              "full_screen"
            );
          }}
        >
          <ListItemIcon>
            <Maximize style={{ color: props.themeColor }} />
          </ListItemIcon>
          <ListItemText primary={"পূর্ণ পর্দা"} />
        </ListItem>
      </List>
      {/* <Divider /> */}

      <div className="bg-light">
        <h5
          className="font-weight-bold pl-4 py-1"
          style={{ color: props.themeColor }}
        >
          অপশন
        </h5>
      </div>
      <List className="text-uppercase    ">
        {/* <ListItem button>
          <ListItemIcon>
            <PrintIcon className="text-icon-color" />
          </ListItemIcon>
          <ListItemText primary={"Print "} />
        </ListItem>
        <ListItem button>
          <ListItemIcon>
            <ShareIcon className="text-icon-color" />
          </ListItemIcon>
          <ListItemText primary={"SHARE"} />
        </ListItem>
        <ListItem button>
          <ListItemIcon>
            <HelpOutlineOutlinedIcon className="text-icon-color" />
          </ListItemIcon>
          <ListItemText primary={"NAVIGATE"} />
        </ListItem> */}
        {localStorage.getItem("customer_details") ? (
          <ListItem
            button
            onClick={() => {
              signOutEvent(
                "signout_click",
                "signout",
                "signout_click",
                "header"
              );
              handleLogout();
            }}
          >
            <ListItemIcon>
              <LogOut style={{ color: props.themeColor }} />
            </ListItemIcon>
            <ListItemText primary={"প্রস্থান"} />
          </ListItem>
        ) : (
          <ListItem
            button
            onClick={() => {
              loginEvents(
                "login_initiated",
                "login_initiated",
                "login_initiated",
                "topbar"
              );
              localStorage.setItem("login_source", "hamburger");

              handleLogin();
            }}
          >
            <ListItemIcon>
              <LogIn style={{ color: props.themeColor }} />
            </ListItemIcon>
            <ListItemText primary={"প্রবেশ করুন"} />
          </ListItem>
        )}
        <ListItem
          button
          onClick={() => {
            // setIsOpenContactModal(!isOpenContactModal);
            window.open("mailto:help@eisamaygold.com");
          }}
        >
          <ListItemIcon>
            <QuestionAnswerIcon style={{ color: props.themeColor }} />
          </ListItemIcon>
          <ListItemText primary={"যোগাযোগ"} />
        </ListItem>
      </List>
    </div>
  );

  return (
    <div>
      {loggedOut && <Redirect to="/login" />}
      {["right"].map((anchor) => (
        <React.Fragment key={anchor}>
          <Tooltip title="More">
            <MoreVertIcon onClick={toggleDrawer(anchor, true)} />
          </Tooltip>
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
          >
            {list(anchor)}
          </Drawer>
        </React.Fragment>
      ))}

      <Modal
        isOpen={isOpenContactModal}
        className="modal_container"
        centered
        size="lg"
        style={{ top: window.innerWidth < 701 && "60px" }}
      >
        <MailModal />
      </Modal>
    </div>
  );
}
