import React, { Fragment } from "react";
import { Container, Card, CardBody, Row, Col } from "reactstrap";
import TopHeader from "./TopHeader";
function AboutPage() {
  return (
    <Fragment>
      <TopHeader />
      <Container>
        <Card
          style={{
            marginTop:
              window.innerWidth > 1132
                ? "110px"
                : localStorage.getItem("contentRec") === "false" &&
                  window.innerWidth < 1133
                ? "60px"
                : localStorage.getItem("contentRec") === "true" &&
                  window.innerWidth < 701
                ? "60px"
                : window.innerWidth < 701
                ? "60px"
                : "110px",
          }}
        >
          <CardBody className="text-muted">
            <div className="d-flex justify-content-center align-items-center border-bottom p-2">
              <h2>About Us</h2>
            </div>
            <h5 className="mt-3">Welcome to e-paper</h5>
            <p>
              The nation's leading media conglomerate with 45 dailies and
              periodicals in 3 languages and 108 editions from 9 centres and a
              combined readership of over 40 million. In fact The Times of India
              Group accounts for 30% of the measured ad spend in the country. We
              include in our stable The Times of India, among the top 2 English
              broadsheets worldwide; and The Economic Times among the top 3
              English business dailies worldwide. The Times of India Group gives
              the highest reach among Indian decision makers, intelligentsia and
              the elite. The Group also has a range of 9 Business2Business
              publications from The Economic Times catering to industry segments
              as diverse as machinery and polymers. In addition its 16 Special
              Interest offerings are targeted at groups of consumers ranging
              from photography to education to annuals on beauty, fashion etc.
            </p>
            <p>
              Established over 160 years ago, this group has entered the
              mainstream of Indian life in every possible way. A dynamic group,
              it has entered the 21st century as a complete media house, with a
              turnover that is the highest in the country.
            </p>
            <p>
              It's new ventures include Times International Media Marketing with
              the role of attracting International business to India through a
              developmental approach. International Media Representation
              represents select mainline and business publications worldwide.
              With a network of over 50 offices across the country and a field
              force of 1000 the coverage of India is most comprehensive and
              perhaps unparalleled.When infotainment became the buzz word Times
              Entertainment , Times New Media, Times Music and Radio Mirchi
              entered this lineage. Times Retail with Planet M outlets staging
              merchandise from music to designer watches, books &amp; cafes. An
              offshoot of its highly rated Economic Times Intelligence Group is
              timesofmoney.com, a bank solutions that empowers Indians &amp; Non
              Resident Indians to make the best financial decisions.
            </p>
            <p>
              The Times Group has created a galaxy of stars of different genres
              from Femina Miss Worlds and Femina Miss Universes all by products
              of Femina Miss India contests; and played hosts to a galaxy of
              stars at the Filmfare Awards. Also saluted the best &amp; the
              brightest at The Economic Times Awards for Corporate Excellence or
              the Brand Equity Quiz. It has touched the lives of its readers
              &amp; consumers through Exhibitions &amp; Fairs - a spectrum from
              Times Utsav Consumer fairs to Education Events.
            </p>
            <p>
              India's first portal site, a venture of Times Internet,
              www.indiatimes.com, includes over 55 websites and almost one
              billion page views a month. www.timesofindia.com is the fifth most
              visited print news and media site from the US.
            </p>
            <p>
              Asia Pacific’s first electronic newspaper,
              http://epaper.timesofindia.com offers a viable advertising option,
              carrying the same benefits of the physical coupled with
              interactivity which offers instantaneous communication options
              unlike anything ever seen before.
            </p>
            <p>
              Your career counselor, advisor, influencer and guide anytime
              anywhere. The first Indian education portal for all education
              needs is here. This portal will cater to the ever-expanding
              student community and learning experience.
            </p>
            <p>
              The philanthropic arm of the group, the Times Foundation, offers
              Non Government Organisations , institutions &amp; others all over
              India, a platform to converge and address the country's
              developmental needs.
            </p>
            <p>
              As a publishing house, it has not only sought to bring news but to
              portray the country's changing social scene. Through its incisive
              editorials, The Times of India Group has effectively moulded
              public opinion and is indeed an accurate barometer of the nation
              today.
            </p>
            <p>
              Its publications are printed using state-of-the art technology at
              the country's most modern printing presses, offerings not only
              comparable to black and white but superior quality colour printing
              as well... the best in the world.
            </p>
            <p>
              The Times of India Group is the only media house to give
              advertisers maximum value for their money and minimum wastage. The
              range of publications, each with its own specific target audience,
              helps advertising managers plan the ideal media mix, optimising
              the advertising budget.
            </p>
          </CardBody>
        </Card>
      </Container>
    </Fragment>
  );
}

export default AboutPage;
