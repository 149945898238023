import React, { useContext, useRef, useEffect } from "react";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import MenuItem from "@material-ui/core/MenuItem";
import JSCookie from "js-cookie";
import MenuList from "@material-ui/core/MenuList";
import "../components/sidebar.css";
import { Link } from "react-router-dom";
import fit_to_width_icon from "../assets/icons/fit_to_width.png";
import { makeStyles } from "@material-ui/core/styles";
import { Columns, Layers, Maximize, Monitor } from "react-feather";
//context
import { AppContext } from "../contexts/AppContext";
import Tooltip from "@material-ui/core/Tooltip";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    height: "22px",
    justifyContent: "center",
  },
  paper: {
    marginRight: theme.spacing(2),
  },
  MenuList: {
    marginTop: "19px",
  },
  MenuItem: {
    fontSize: "13px",
    fontWeight: "500",
  },
}));

export default function ToggleMenuEdition(props) {
  const classes = useStyles();
  // global state
  const {
    setIsFitToHeight,
    isPopularDrawerOpen,
    setWidth,
    refactorHighlight,
    vw,
    setFit2page,
    setIsPopularDrawerOpen,
    ratioMainPage,
    initialEdition,
    editionData,
    handleEdition,
    initialPublication,
  } = useContext(AppContext);
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }

  const onFitToHeight = () => {
    setIsFitToHeight((currState) => currState + 1);

    setWidth((currState) => {
      const targetHeight = window.innerHeight - 200;

      const newWidth = ratioMainPage * targetHeight;
      refactorHighlight(newWidth, currState);
      return newWidth;
    });
    setFit2page(false);
  };
  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);
  const current_path = window.location.pathname;
  return (
    <div className={classes.root}>
      <div>
        <Tooltip title="Editions">
          <Layers
            className="feather-29"
            ref={anchorRef}
            // color for dark mode
            aria-controls={open ? "menu-list-grow" : undefined}
            aria-haspopup="true"
            onClick={handleToggle}
          />
        </Tooltip>

        <Popper
          open={props.open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === "bottom" ? "center top" : "center bottom",
                backgroundColor: "#f5f5f5",
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={props.handleClose}>
                  <MenuList
                    className={classes.MenuList}
                    autoFocusItem={props.open}
                    id="menu-list-grow"
                    onKeyDown={handleListKeyDown}
                  >
                    {editionData &&
                      editionData.length > 0 &&
                      editionData.map((item, index) => {
                        if (item.editionid !== initialEdition?.editionid)
                          return (
                            <MenuItem key={index}>
                              <p
                                className="menu-name"
                                onClick={async () => {
                                  await JSCookie.remove("fromArchive");
                                  handleEdition(item, index);
                                  // ReactGA.pageview(window.location.href, [
                                  //   `${item.editionid}-${item.editionname}`,
                                  // ]);
                                  window.location.assign(
                                    `${
                                      window.location.origin
                                    }/eisamay/${initialPublication?.publicationname
                                      ?.split(" ")
                                      .join("-")
                                      .toLowerCase()}/${item?.editionname
                                      ?.split(" ")
                                      .join("-")
                                      .toLowerCase()}`
                                  );
                                }}
                              >
                                <Link
                                  // to={`/${
                                  //   current_path.split("/")?.[1]
                                  // }/${item?.editionname
                                  //   ?.split(" ")
                                  //   .join("-")
                                  //   .toLowerCase()}`}
                                  // target="_self"
                                  style={{ color: "inherit" }}
                                >
                                  {item.editionnameBeng}
                                </Link>
                              </p>
                            </MenuItem>
                          );
                      })}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    </div>
  );
}
