import React, { useContext, useEffect, useState } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { myAccountEvent, homeEvent, loginEvents } from "../functions/cleverTap";
import {
  MoreVertical,
  X,
  Home,
  Headphones,
  HelpCircle,
  Bell,
  Smile,
  Hexagon,
  User,
  Menu,
} from "react-feather";
import { Col, Row, Button } from "reactstrap";
// context
import { AppContext } from "../contexts/AppContext";
import "./eisamaymenu.css";
const useStyles = makeStyles({
  list: {
    width: 330,
  },
  fullList: {
    width: "auto",
  },
});

export default function EisamayMenu(props) {
  // global state
  const { logo, themeColor } = useContext(AppContext);
  const [customerFirstName, setCostomerFirstName] = useState(" ");
  const classes = useStyles();
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const nonLoggedInMenu = [
    { label: "হোম", icon: <Home width="20px" /> },
    { label: "ফ্রি ট্রায়াল শুরু করুন", icon: <Smile width="20px" /> },
    { label: "সাবস্ক্রিপশন প্ল্যান কিনুন", icon: <Headphones width="20px" /> },
    { label: "নিউজলেটার-এ সাবস্ক্রাইব করুন", icon: <Bell width="20px" /> },
    { label: "এই সময় গোল্ড", icon: <Hexagon width="20px" /> },
    { label: "help@eisamaygold.com", icon: <HelpCircle width="20px" /> },
  ];
  const loggedInMenu = [
    { label: "হোম", icon: <Home width="20px" /> },
    { label: "মাই অ্যাকাউন্ট/আমার অ্যাকাউন্ট", icon: <User width="20px" /> },
    { label: "নিউজলেটার-এ সাবস্ক্রাইব করুন", icon: <Bell width="20px" /> },
    { label: "এই সময় গোল্ড", icon: <Hexagon width="20px" /> },
    { label: "help@eisamaygold.com", icon: <HelpCircle width="20px" /> },
  ];
  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const extractFirstName = () => {
    if (localStorage.getItem("customer_details")) {
      let customerDetails = localStorage.getItem("customer_details");
      if (customerDetails)
        customerDetails = JSON.parse(customerDetails)?.full_name?.split(" ")[0];
      setCostomerFirstName(customerDetails);
    }
  };
  useEffect(() => {
    extractFirstName();
  }, [props]);

  const handleLogin = () => {
    window.open(
      `https://jsso.indiatimes.com/sso/identity/login?channel=${process.env.REACT_APP_CHANNEL_SSO_NAME}&ru=${window.location.origin}/eisamay/jsso`,
      "Ratting",
      "width=600,height=600,toolbar=0,status=0,"
    );
  };

  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === "top" || anchor === "bottom",
      })}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        <Row
          className="py-2 mx-1"
          style={{ borderBottom: "2px solid #dbd1d1" }}
        >
          <Col className="text-start pl-2">
            <img
              width="80px"
              src={`/eisamay-assets/logo/timesgroup.png`}
              alt="logo"
            />
          </Col>
          <Col className="text-right pr-2 pt-1">
            <X className="cursor-pointer" width="20px" />
          </Col>
        </Row>
        {localStorage.getItem("customer_details") ? (
          <Row
            className="py-2 mx-1"
            style={{ borderBottom: "2px solid #dbd1d1" }}
          >
            <Col className="text-start pl-2">
              <p className="login-welcome-text mb-1">নমস্কার</p>
              <b className="login-inro-text mb-1" style={{ fontSize: "20px" }}>
                {customerFirstName}
              </b>
            </Col>
          </Row>
        ) : (
          <Row
            className="py-2 mx-1"
            style={{ borderBottom: "2px solid #dbd1d1" }}
          >
            <Col className="text-start pl-2">
              <Button
                onClick={() => {
                  loginEvents(
                    "login_initiated",
                    "login_initiated",
                    "login_initiated",
                    "topbar"
                  );
                  handleLogin();
                  localStorage.setItem("login_source", "hamburger");
                }}
                className="login-btn-nbt-menu mb-2 mt-1"
                style={{
                  border: `2px solid ${themeColor}`,
                  color: themeColor,
                }}
              >
                लॉग इन
              </Button>
              <p className="login-inro-text mb-1">
                লগ ইন করুন ও পান ৩ দিনের আনলিমিটেড অ্যাক্সেস
              </p>
            </Col>
          </Row>
        )}
        {localStorage.getItem("customer_details")
          ? loggedInMenu.map((text, index) => (
              <>
                <ListItem
                  button
                  key={text.label}
                  onClick={() => {
                    if (index === 0) {
                      homeEvent(
                        "home_button",
                        "home_button",
                        "home_button",
                        "header"
                      );

                      window.location.assign(
                        `${window.location.origin}/eisamay/home`
                      );
                    }
                    if (index === 1) {
                      myAccountEvent(
                        "my_account",
                        "my_account",
                        "my_account",
                        "header"
                      );
                      window.open(
                        `${process.env.REACT_APP_CHANNEL_URL}/${process.env.REACT_APP_CHANNEL_NAME}/myaccount`
                      );
                    }
                    if (index === 2) {
                      window.open(
                        `${process.env.REACT_APP_CHANNEL_URL}/${process.env.REACT_APP_CHANNEL_NAME}/subscription`,
                        "__blank"
                      );
                    }
                    if (index === 3) {
                      window.open(`${process.env.REACT_APP_CHANNEL_URL}`);
                    }
                    if (index === 4) {
                      window.open("mailto:help@eisamaygold.com");
                    }
                  }}
                >
                  <ListItemIcon>{text?.icon}</ListItemIcon>
                  <ListItemText
                    className="menu-item-nbt"
                    primary={text.label}
                  />
                </ListItem>
                <Divider />
              </>
            ))
          : nonLoggedInMenu.map((text, index) => (
              <>
                <ListItem
                  button
                  key={text.label}
                  onClick={() => {
                    if (index === 0) {
                      homeEvent(
                        "home_button",
                        "home_button",
                        "home_button",
                        "header"
                      );

                      window.location.assign(
                        `${window.location.origin}/eisamay/home`
                      );
                    }
                    if (index === 1) {
                      loginEvents(
                        "login_initiated",
                        "login_initiated",
                        "login_initiated",
                        "topbar"
                      );
                      handleLogin();
                      localStorage.setItem("login_source", "hamburger");
                    }
                    if (index === 2) {
                      window.open(
                        `${process.env.REACT_APP_CHANNEL_URL}/${process.env.REACT_APP_CHANNEL_NAME}/plans`,
                        "__blank"
                      );
                    }
                    if (index === 3) {
                      window.open(
                        `${process.env.REACT_APP_CHANNEL_URL}/${process.env.REACT_APP_CHANNEL_NAME}/subscription`,
                        "__blank"
                      );
                    }
                    if (index === 4) {
                      window.open(`${process.env.REACT_APP_CHANNEL_URL}`);
                    }
                    if (index === 5) {
                      window.open("mailto:help@eisamaygold.com");
                    }
                  }}
                >
                  <ListItemIcon>{text?.icon}</ListItemIcon>
                  <ListItemText
                    className="menu-item-nbt"
                    primary={text.label}
                  />
                </ListItem>
                <Divider />
              </>
            ))}
      </List>
    </div>
  );

  return (
    <div>
      {["left"].map((anchor) => (
        <React.Fragment key={anchor}>
          <Menu
            onClick={toggleDrawer(anchor, true)}
            className="feather-29 cursor-pointer"
            color={themeColor}
          />
          <Drawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
          >
            {list(anchor)}
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  );
}
