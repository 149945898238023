import React, { useState, useContext, useEffect, useRef } from "react";
import JSCookie from "js-cookie";
import axios from "axios";
import moment from "moment";
import "./landing-page.css";
import defaultImg from "../assets/images/default_image.png";
import { Link, Redirect, useHistory } from "react-router-dom";
import publicationJson from "../config/Publications.json";
import Tooltip from "@material-ui/core/Tooltip";
import CookiesChoice from "./CookiesChoice";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "react-modal";
import { Helmet } from "react-helmet";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import EisamayMenu from "./EisamayMenu";
import topBannerLp from "../assets/images/1000x200Banner1.jpeg";
import topBannerLpMob from "../assets/images/300x50Banner1.jpeg";
import { Menu, Bell, X } from "react-feather";
import ToggleMenu5 from "./ToggleMenu5";
import {
  bpnSubscribe,
  displayEvents,
  downloadEvent,
  homeEvent,
  loginEvents,
  signOutEvent,
  TOCEvent,
} from "../functions/cleverTap";
//context
import { AppContext } from "../contexts/AppContext";
import { ModalBody, Row, Col, Card, Button } from "reactstrap";
import styled from "styled-components";

import { pushNotifications } from "../functions/BPN";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    left: "48%",
    bottom: "31%",
    width: "fit-content",
    zIndex: 99999,
    color: "black",
    backgroundColor: "rgb(255 255 255 / 0%) !important",
  },
}));

const Nav = styled.div`
  background: #ffff;
  // height: 57px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  // border-radius: 5px;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
  position: fixed;
  z-index: 999;
  width: -webkit-fill-available;
  left: 0px;
`;

const googletag = window.googletag || (window.googletag = { cmd: [] });

const createScope = (action) => action && action();

const GPTAdsManager = createScope(() => {
  let initialized = false;
  const initializeAds = (initialLoading = false, singleRequest = true) => {
    if (initialized) {
      return;
    }
    initialized = true;
    googletag.cmd.push(() => {
      const pubads = googletag.pubads();
      if (!initialLoading) {
        pubads.disableInitialLoad();
      }
      if (singleRequest) {
        pubads.enableSingleRequest();
      }
      googletag.enableServices();
      // googletag.pubads().addEventListener("slotOnload", function (event) {
      //   var slot = event.slot;
      //   console.log(
      //     "Creative iframe for slot",
      //     slot.getSlotElementId(),
      //     "has loaded."
      //   );
      // });
      setInterval(() => {
        googletag.pubads().refresh();
      }, 30000);
    });
  };
  const createSlot = (adPath, adWidth, adHeight, elementId) => {
    initializeAds(); // only if not initialized yet
    let slot = null;
    googletag.cmd.push(() => {
      const size = adWidth & adHeight ? [adWidth, adHeight] : ["fluid"];
      const tmp = googletag.defineSlot(adPath, size, elementId);
      if (tmp) {
        slot = tmp;
        tmp.addService(googletag.pubads());
      }
    });
    const display = () => {
      if (slot) {
        googletag.cmd.push(() => {
          const pubads = googletag.pubads();
          pubads.refresh([slot]);
        });
      }
    };
    const refresh = () => {
      if (slot) {
        googletag.cmd.push(() => {
          const pubads = googletag.pubads();
          pubads.refresh([slot]);
        });
      }
    };
    const destroy = () => {
      if (slot) {
        const tmp = slot;
        googletag.cmd.push(() => {
          const pubads = googletag.pubads();
          googletag.destroySlots([tmp]);
        });
        slot = null;
      }
    };
    return { display, refresh, destroy };
  };
  return { initializeAds, createSlot };
});

export default function HomePageNbt(props) {
  const history = useHistory();
  // global state
  const {
    mainPageLOader,
    setMainPageLoader,
    getPublicationsData,
    s3Source,
    isEnableAds,
    setIsOpenUpdate,
    isOpenUpdate,
    customerDetails,
    isLandingPage,
    userLoggedOut,
    loggedInUser,
  } = useContext(AppContext);

  const classes = useStyles();
  const [toggleMenu5Open, setToggleMenu5Open] = useState(false);
  const [hpBanner, setHpBanner] = useState();

  const toggleMenu5 = () => setToggleMenu5Open((currState5) => !currState5);
  const handleToggleMenu5Close = () => setToggleMenu5Open(false);
  const [customerFirstName, setCostomerFirstName] = useState(" ");

  const extractFirstName = () => {
    if (localStorage.getItem("customer_details")) {
      let customerDetails = localStorage.getItem("customer_details");
      if (customerDetails)
        customerDetails = JSON.parse(customerDetails)?.full_name?.split(" ")[0];
      setCostomerFirstName(loggedInUser?.firstName);
    }
  };
  useEffect(() => {
    extractFirstName();
  }, [customerDetails]);

  const [isOpenPub, setIsOpenPub] = React.useState(false);
  const [isOpenEdition, setIsOpenEdition] = React.useState(false);

  const togglePublication = () => setIsOpenPub((prevState) => !prevState);
  const toggleEdition = () => setIsOpenEdition((prevState) => !prevState);

  const [publicationsData, setPublicationsData] = useState([]);

  const [initialPublication, setInitialPublication] = useState({});
  const [currentPublication, setCurrentPublication] = useState({});
  const [dateIndexData, setDateIndexData] = useState([]);
  const [todaysDate, setTodaysDate] = useState(null);
  const [dateArray, setDateArray] = useState([]);
  const [displayTodaysImage, setDisplayTodaysImage] = useState(true);
  const [editionData, setEditionsData] = useState([]);
  const [logo, setLogo] = useState();
  const [imgLpLoaded, setImgLpLoaded] = useState(false);
  const [themeColor, setThemeColor] = useState();
  const [initialEdition, setInitialEdition] = useState({});
  const [dayIndexData, setDayIndexData] = useState([]);
  const [sectionData, setSectionData] = useState([]);
  const [currentPage, setCurrentPage] = useState({});
  const [favIcon, setfavIcon] = useState();
  const [todays_date, set_todays_date] = useState();
  const loading = useRef(true);

  const getData = async (pubindex, edindex) => {
    try {
      if (
        JSCookie.get("espubindex") !== pubindex ||
        JSCookie.get("esedindex") !== edindex ||
        !currentPublication ||
        !initialEdition ||
        dateIndexData.length === 0
      ) {
        await setDisplayTodaysImage(false);
        var publication = 0,
          edition = 0;

        // key-value
        if (JSCookie.get("espubindex")) {
          publication = await JSCookie.get("espubindex");
        } else {
          await JSCookie.set("espubindex", publication, { expires: 3 });
        }
        if (JSCookie.get("esedindex")) {
          edition = await JSCookie.get("esedindex");
        } else {
          await JSCookie.set("esedindex", edition, { expires: 3 });
        }
        let tempInitialPub = "";
        let tempInitialEdition = "";

        const favicon = await document.getElementById("favicon");

        tempInitialPub = publicationJson.Publications[Number(publication)];

        await setInitialPublication({ ...tempInitialPub, index: publication });
        await setCurrentPublication({ ...tempInitialPub, index: publication });

        tempInitialEdition = await publicationJson.Publications[
          Number(publication)
        ].editions[Number(edition)];
        await setInitialEdition({ ...tempInitialEdition, index: edition });

        await setPublicationsData(publicationJson.Publications); // array of publications

        await setEditionsData(
          publicationJson.Publications[Number(publication)].editions
        ); // array of editions of the await set publication
        document.title =
          publicationJson.Publications[Number(publication)]?.publicationname +
          " | e-Edition";
        await setThemeColor(
          publicationJson.Publications[Number(publication)]?.theme_color
        );
        await setLogo(tempInitialPub.logo);

        // await favicon.setAttribute("href", tempInitialPub.favicon);

        if (
          Object.keys(tempInitialPub).length > 0 &&
          Object.keys(tempInitialEdition).length > 0
        ) {
          let json = "DateIndex";
          if (process.env.REACT_APP_ENV === "PROD") {
            json = "datekey";
          }
          const result = await getDates(
            tempInitialPub,
            tempInitialEdition,
            Number(publication),
            Number(edition),
            json
          );

          let newDateIndex = [];
          if (process.env.REACT_APP_ENV !== "PROD") {
            for (let i = 0; i < result.DateIndex.length; i++) {
              const ele = result.DateIndex[i];
              newDateIndex.push({
                Date: ele,
                Timestamp: new Date().getTime(),
              });
            }
          } else {
            if (result && result.length > 0) {
              newDateIndex = result;
            }
          }

          //sort to get latest date first
          const dateIndexSorted = await newDateIndex.sort((a, b) =>
            new Date(a.Date.split("-").reverse().join("-")) <
            new Date(b.Date.split("-").reverse().join("-"))
              ? 1
              : -1
          );

          await set_todays_date(
            dateIndexSorted[0].Date.split("-").reverse().join("/")
          );
          await JSCookie.set("eseddate", dateIndexSorted[0].Date);
          await setDateIndexData(dateIndexSorted);
          await setDisplayTodaysImage(true);
        }
      }
    } catch (error) {
      console.log("error: ", error);
    }
  };
  //get dates with timestamp
  const getDates = async (
    tempInitialPub,
    tempInitialEdition,
    publication,
    edition,
    json
  ) => {
    let result = [];
    await setDisplayTodaysImage(false);

    const url = `${
      publicationJson[process.env.REACT_APP_ENV]
    }/PublicationData/${
      tempInitialPub.publicationcode ??
      publicationJson?.Publications?.[publication]?.publicationcode
    }/${
      tempInitialEdition.editionid ??
      publicationJson?.Publications?.[publication]?.editions[edition]?.editionid
    }/${json}.json`;

    await axios
      .get(url)
      .then(async (res) => {
        result = await res.data;
      })
      .catch((err) => {
        console.log("err: ", err);
      });
    return result;
  };

  useEffect(async () => {
    await getData(JSCookie.get("espubindex"), JSCookie.get("esedindex"));
  }, []);

  const handlePublication = async (publication, index) => {
    // await setCurrentPublication(publication);
    await JSCookie.set("espubindex", index, { expires: 3 });
    await JSCookie.set("esedindex", 0, { expires: 3 });
    await getData(index, 0);
  };

  const [slotsData] = useState([
    {
      path: `/1064661/Epaper_Eisamay/Eeisamay_DTP_HP_ATF_728`,
      width: 728,
      height: 90,
      divId: `div-gpt-ad-1648018986998-0`,
    },
    {
      path: `/1064661/Epaper_Eisamay/Eeisamay_DTP_HP_ATF_300`,
      width: 300,
      height: 250,
      divId: `div-gpt-ad-1648018905638-0`,
    },
    {
      path: `/1064661/Epaper_Eisamay/Eeisamay_DTP_HP_BTF_300`,
      width: 300,
      height: 250,
      divId: `div-gpt-ad-1648018826780-0`,
    },
    {
      path: `/1064661/Epaper_Eisamay/Eeisamay_DTP_HP_BTF_728`,
      width: 728,
      height: 90,
      divId: `div-gpt-ad-1648019036520-0`,
    },
    {
      path: `1064661/Epaper_Eisamay_WAP/Eeisamay_WAP_HP_TOP_320`,
      width: 300,
      height: 50,
      divId: `div-gpt-ad-1648019136798-0`,
    },
  ]);
  const handleEdition = async (edition, index) => {
    await JSCookie.set("esedindex", index, { expires: 3 });
    await getData(currentPublication?.index, index);
  };

  const handleDate = async (date) => {
    await JSCookie.set("eseddate", date);
    await getData();
  };
  useEffect(() => {
    async function initializeGAds() {
      for (let i = 0; i < slotsData.length; i++) {
        const ele = slotsData[i];
        const slot = GPTAdsManager.createSlot(
          ele.path,
          ele.width,
          ele.height,
          ele.divId
        );
        await slot.display();
        // await slot.refresh();
      }
      GPTAdsManager.initializeAds(false, true);
      setTimeout(() => {
        if (window && window?.googletag?.pubads())
          window.googletag.pubads().refresh();
      }, 1000);
    }
    initializeGAds();
  }, []);

  useEffect(() => {
    if (dateIndexData && dateIndexData.length > 0) {
      set_todays_date(dateIndexData[0].Date.split("-").reverse().join("/"));
    }
  }, [dateIndexData]);

  const handleLogin = () => {
    window.open(
      `https://jsso.indiatimes.com/sso/identity/login?channel=${process.env.REACT_APP_CHANNEL_SSO_NAME}&ru=${window.location.origin}/eisamay/jsso`,
      "Ratting",
      "width=600,height=600,toolbar=0,status=0,"
    );
  };

  useEffect(() => {
    const getBannerData = async () => {
      const url = `h${process.env.REACT_APP_CMS_URL}/banner/epaper/${process.env.REACT_APP_CHANNEL_ID}`;
      await axios.get(url).then(async (res) => {
        res?.data?.data.map((data) => {
          data.img = `${process.env.REACT_APP_CMS_URL?.replace(
            "/api/v1",
            ""
          )}/image-resizer/eisamaygold${data.img}`;
        });
        await setHpBanner(
          res?.data?.data.filter((v) => v.label === "Desktop Homepage")[0]
        );
      });
    };
    getBannerData();
  }, []);

  return (
    <React.Fragment>
      <Helmet>
        <title>{`Eisamay Times Newspaper online | Read and Download today's Bengali epaper | পড়ুন দেশের প্রতিষ্ঠিত সংবাদপত্র এইসময় টাইমস`}</title>
        <meta
          name="description"
          content={`Read online Eisamay Times ePaper. Eisamay is an online Bengali newspaper where you get to read your city daily breaking news updates. Download Eisamay Bengali ePaper. পড়ুন দেশের আজকের সব খবর অনলাইন epaper.eisamay.com এর ePaper সেক্শনে`}
        />
        <meta
          name="Keywords"
          content={`Eisamay Times Epaper, News in Bengali, Online Newspaper, এইসময় টাইমস ই -পেপার , Download bengali epaper, Newspaper in bengali, bengali News paper, bengali newspapers, Newspaper online, online bengali Newspaper, daily epaper, Latest Online News, Eisamay bengali ePaper, E-Paper, breaking bengali news, latest news in bengali`}
        />

        <link rel="canonical" href="https://epaper.timesgroup.com/eisamay/" />
        <meta content={`The Times Group`} name="author" />
        <meta content={`The Times Group`} name="Publisher" />
        <meta name="robots" content="index" />
        <meta
          content={new Date(`${dateArray}`)}
          property="article:published_time"
        />
        <meta
          property="og:site_name"
          content={`${initialPublication?.publicationcode} epaper`}
        />
        <meta property="og:image:type" content="image/jpeg" />
        <meta property="og:locale" content="en_IN" />
        <meta
          content={new Date(`${dateArray}`)}
          property="article:modified_time"
        />
        <meta
          property="og:title"
          content={`${initialPublication?.publicationname} epaper`}
        />
        <meta
          name="breadcrumbs"
          content={`Home>Eisamay  ${initialEdition?.editionname} E-Paper`}
        />
        <meta property="og:locale" content={`hi_IN`} />
        {/* <meta property="og:description" content={dataRet?.synopsis} /> */}
        {/* <meta name="Keywords" content={keywords} /> */}

        {/* <meta name="news_keywords" content={keywords} /> */}
        <meta property="og:image" content={`/${logo}`} />
        <meta property="og:image:width" content="500" />
        <meta property="og:image:height" content="500" />
      </Helmet>
      {localStorage.getItem("continentCode") === "EU" &&
        (!localStorage.getItem("webAnalytics") ||
          !localStorage.getItem("contentRec")) && <CookiesChoice {...props} />}
      <div className="landing-page-main-div">
        <div>
          <div
            style={{
              position: "fixed",
              top: "0px",
              zIndex: "999",
              width: "-webkit-fill-available",
            }}
          >
            <Card
              className="header-card-landing border-0 bg-white "
              style={{
                height: "90px",
              }}
            >
              <Row className="bg-white pb-1 ">
                <Col
                  className="desktop-only"
                  xs="12"
                  sm="12"
                  md="3"
                  lg="3"
                  xl="3"
                  style={{
                    marginTop: "5px",
                    display: "flex",
                    justifyContent: "space-around",
                  }}
                >
                  <img
                    className=""
                    style={{
                      maxHeight: window.innerWidth > 700 ? "85px" : "58px",
                    }}
                    src="/eisamay-assets/logo/logo-main-eis.png"
                    alt="Home page"
                  />
                </Col>

                <Col
                  className="top-ad-landing desktop-only"
                  xs="12"
                  sm="12"
                  md="6"
                  lg="6"
                  xl="6"
                >
                  {localStorage.getItem("contentRec") === "true" && (
                    <div>
                      <div id="div-gpt-ad-1648018986998-0" />
                    </div>
                  )}
                </Col>
                <Col
                  className="top-ad-landing-mob"
                  xs="12"
                  sm="12"
                  md="6"
                  lg="6"
                  xl="6"
                >
                  <Card className="border-0">
                    {localStorage.getItem("contentRec") === "true" && (
                      <div>
                        <div id="div-gpt-ad-1648019136798-0" />
                      </div>
                    )}
                  </Card>
                </Col>

                <Col md="3" lg="3" xl="3"></Col>
              </Row>
            </Card>
            <div className="border-landing desktop-only"></div>
          </div>

          <Card className="border-0">
            <Nav //for desktop view only
              className="homepage-nav-desktop desktop-only"
              style={{ color: themeColor, backgroundColor: "white" }}
            >
              <Row className="pt-1">
                <Col className="center-nbt-icon">
                  <Row className="w-100 pl-1">
                    <span className="pr-4 pt-2">
                      <EisamayMenu />
                    </span>
                  </Row>
                </Col>
                <Col>
                  {!localStorage.getItem("customer_details") &&
                    userLoggedOut && (
                      <Row className="justify-content-center">
                        <button
                          onClick={() => {
                            handleLogin();
                            localStorage.setItem(
                              "login_source",
                              "startFreeTrial"
                            );
                          }}
                          className="btn-login-tbpar px-2 ml-3 mr-4 mt-2"
                          style={{ backgroundColor: "#f2923d", color: "white" }}
                        >
                          <p className="m-0"> {"Start Free Trial"} </p>
                        </button>
                        <a href="https://eisamaygold.timesgroup.com/plans">
                          <button
                            // onClick={() => {
                            //   loginEvents(
                            //     "login_initiated",
                            //     "login_initiated",
                            //     "login_initiated",
                            //     "topbar"
                            //   );
                            //   handleLogin();
                            // }}
                            className="btn-login-tbpar px-2 mt-2"
                            style={{
                              backgroundColor: "#f2923d",
                              color: "white",
                            }}
                          >
                            <p className="m-0"> {"Subscribe"} </p>
                          </button>
                        </a>
                      </Row>
                    )}
                </Col>
                <Col sm="4" md="4" className="">
                  <Row style={{ justifyContent: "end" }}>
                    <Col
                      md="5"
                      lg="4"
                      className="text-right pl-1 cursor-pointer pt-2"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "end",
                      }}
                    >
                      <a
                        href="https://eisamaygold.timesgroup.com"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img
                          width="165px"
                          src={`${process.env.PUBLIC_URL}/logo/nbtgoldlogo.png`}
                          alt="Eisamay gold"
                        />
                      </a>
                    </Col>

                    <span
                      className="desktop-only"
                      style={{ textDecoration: "none", color: themeColor }}
                    >
                      {localStorage?.getItem("customer_details") &&
                      !userLoggedOut ? (
                        <Col
                          id="account"
                          className=" pr-3 pl-1 cursor-pointer hp-btn-loggedin mt-0"
                          onClick={toggleMenu5}
                        >
                          <ToggleMenu5
                            isLandingPage={isLandingPage}
                            customerFirstName={customerFirstName}
                            themeColor={themeColor}
                            open={toggleMenu5Open}
                            handleClose={handleToggleMenu5Close}
                            homepage={true}
                          />
                        </Col>
                      ) : (
                        <Col className=" pr-3 pl-1 cursor-pointer hp-btn-loggedin">
                          <button
                            onClick={() => {
                              localStorage.setItem(
                                "login_source",
                                "top_catbar"
                              );

                              loginEvents(
                                "login_initiated",
                                "login_initiated",
                                "login_initiated",
                                "topbar"
                              );
                              handleLogin();
                            }}
                            className="btn-login-tbpar mt-2"
                            style={{ width: "5rem" }}
                          >
                            <p className="m-0"> {"Log In"} </p>
                          </button>
                        </Col>
                      )}
                    </span>
                  </Row>
                </Col>
              </Row>
            </Nav>
            <Nav //for mobile view only
              className="homepage-nav-mob mob-only"
              style={{
                color: themeColor,
                backgroundColor: "white",
                top:
                  localStorage.getItem("contentRec") === "true"
                    ? "60px"
                    : "0px",
              }}
            >
              <Row
                className="m-auto"
                style={{
                  height: "-webkit-fill-available",
                  alignItems: "center",
                }}
              >
                <Col xs="5" sm="5">
                  <Row style={{ alignItems: "center" }}>
                    <span className=" pt-2">
                      <EisamayMenu />
                    </span>
                    <div style={{ textDecoration: "none", color: themeColor }}>
                      {localStorage?.getItem("customer_details") &&
                      !userLoggedOut ? (
                        <span
                          id="account"
                          className=" cursor-pointer hp-btn-loggedin mt-0 mob-only"
                          onClick={toggleMenu5}
                        >
                          <ToggleMenu5
                            customerFirstName={customerFirstName}
                            themeColor={themeColor}
                            open={toggleMenu5Open}
                            handleClose={handleToggleMenu5Close}
                            homepage={true}
                          />
                        </span>
                      ) : (
                        <span className="cursor-pointer hp-btn-loggedin">
                          <button
                            onClick={() => {
                              loginEvents(
                                "login_initiated",
                                "login_initiated",
                                "login_initiated",
                                "topbar"
                              );
                              localStorage.setItem(
                                "login_source",
                                "top_catbar"
                              );

                              handleLogin();
                            }}
                            className="btn-login-tbpar"
                            style={{}}
                          >
                            <p className="m-0"> {"Log In"} </p>
                          </button>
                        </span>
                      )}
                    </div>
                  </Row>
                </Col>
                <Col
                  xs="3"
                  sm="3"
                  style={{ justifyContent: "center", display: "flex" }}
                >
                  <img
                    width="70px"
                    src="/eisamay-assets/logo/logo-main-eis.png"
                    alt="logo"
                  />
                </Col>

                <Col xs="4" sm="4">
                  <Row style={{ alignItems: "center", justifyContent: "end" }}>
                    <span className="pr-2">
                      <a href="https://eisamaygold.timesgroup.com">
                        <img
                          width="23px"
                          src="https://navbharattimes.indiatimes.com/navbharatgold/icons/goldfavicon.ico"
                          alt="gold icon"
                        />
                      </a>
                    </span>
                    {/* </Col> */}
                  </Row>
                </Col>
              </Row>
            </Nav>
          </Card>
          {window.innerWidth < 701 && (
            <>
              {!localStorage.getItem("customer_details") && userLoggedOut && (
                <Row className="hp_cta_container_mob">
                  <span className="py-2">
                    <button
                      onClick={() => {
                        localStorage.setItem("login_source", "startFreeTrial");

                        handleLogin();
                      }}
                      style={{ backgroundColor: "#f2923d", color: "white" }}
                      className="btn-trial-tbpar px-2 mx-3 my-0"
                    >
                      <p className="m-0"> {"Start Free Trial"} </p>
                    </button>
                    <a href="https://eisamaygold.timesgroup.com/plans">
                      <button
                        // onClick={() => {
                        //   loginEvents(
                        //     "login_initiated",
                        //     "login_initiated",
                        //     "login_initiated",
                        //     "topbar"
                        //   );
                        //   handleLogin();
                        // }}
                        style={{
                          backgroundColor: "#f2923d",
                          color: "white",
                        }}
                        className="btn-trial-tbpar px-2 my-0 "
                      >
                        <p className="m-0"> {"Subscribe"} </p>
                      </button>
                    </a>
                  </span>
                </Row>
              )}
            </>
          )}
          <div
            className="landing-cotainer-scroll"
            style={{
              height:
                window.innerWidth > 700
                  ? window.innerHeight - 100
                  : window.innerHeight - 20,
              // paddingTop:
              //   localStorage.getItem("customer_details") &&
              //   JSON.parse(localStorage.getItem("customer_details"))
              //     .access_type === "paid" &&
              //   window.innerWidth < 376
              //     ? "0px"
              //     : localStorage.getItem("customer_details") &&
              //       JSON.parse(localStorage.getItem("customer_details"))
              //         .access_type === "paid" &&
              //       window.innerWidth > 375
              //     ? "30px"
              //     : localStorage.getItem("customer_details") &&
              //       JSON.parse(localStorage.getItem("customer_details"))
              //         .access_type !== "paid" &&
              //       window.innerWidth < 376
              //     ? "50px"
              //     : localStorage.getItem("customer_details") &&
              //       JSON.parse(localStorage.getItem("customer_details"))
              //         .access_type !== "paid" &&
              //       window.innerWidth > 375
              //     ? "90px"
              //     : "0px",
              paddingTop:
                window.innerWidth < 700 &&
                localStorage.getItem("customer_details")
                  ? "7rem"
                  : "0rem",
            }}
          >
            <Row
              className="  centre-ipad justify-content-center px-2"
              style={{ paddingTop: window.innerWidth > 700 ? "10rem" : "21px" }}
            >
              <Col sm="12" md={window.innerWidth > 1280 ? "6" : "9"}>
                <Row className="px-2">
                  {publicationsData[0]?.editions?.map((item, i) => {
                    return (
                      <>
                        <Col
                          key={item.editionid + "key"}
                          xs="6"
                          sm="4"
                          md="3"
                          lg="3"
                          className="mb-3 px-1"
                          onClick={async () => {
                            await JSCookie.remove("fromArchive");
                            await getPublicationsData(true);
                            window.location.assign(
                              `${
                                window.location.origin
                              }/eisamay/${initialPublication?.publicationname
                                ?.toLowerCase()
                                .split(" ")
                                .join("-")}/${item?.editionname
                                ?.toLowerCase()
                                .split(" ")
                                .join("-")}`
                            );
                          }}
                        >
                          <img
                            onError={(e) => (e.target.src = defaultImg)}
                            onLoad={() => {
                              setImgLpLoaded(true);
                            }}
                            className="ext-image-nbt"
                            src={
                              displayTodaysImage &&
                              dateIndexData?.length > 0 &&
                              `${s3Source}/PublicationData/${
                                initialPublication?.publicationcode
                              }/${
                                item?.editionid ??
                                publicationJson?.Publications?.[
                                  Number(JSCookie.get("espubindex"))
                                ]?.editions[JSCookie.get("esedindex")]
                                  ?.editionid
                              }/${
                                JSCookie.get("eseddate")
                                  ? JSCookie.get("eseddate")
                                      ?.split("-")
                                      ?.reverse()
                                      .join("/")
                                  : todays_date
                              }/TodaysPage/todaysthumbimage_${
                                item?.editionid ??
                                publicationJson?.Publications?.[
                                  Number(JSCookie.get("espubindex"))
                                ]?.editions[JSCookie.get("esedindex")]
                                  ?.editionid
                              }.jpg?v=${dateIndexData[0]?.Timestamp}`
                            }
                            alt={
                              "image" + dateIndexData?.length > 0
                                ? dateIndexData[0].Date.split("-")
                                    .reverse()
                                    .join("/")
                                : todays_date
                            }
                            style={{ cursor: "pointer" }}
                          />{" "}
                          {imgLpLoaded && (
                            <div
                              className="page-font-bottombar text-white text-center"
                              style={{
                                borderRadius: " 0px 0px 5px 5px ",
                                backgroundColor: "#F2923D",
                                padding: " 0px 0px",
                                width: "100%",
                                maxWidth: "220px",
                              }}
                            >
                              {item?.editionnameBeng}
                            </div>
                          )}
                        </Col>
                      </>
                    );
                  })}
                </Row>
              </Col>

              <Col
                className=" not-ipad mt-2"
                md="3"
                lg="3"
                xl="3"
                style={{ position: "inherit" }}
              >
                {localStorage.getItem("contentRec") === "true" && (
                  <div className=" landing-ad desktop-only">
                    <div id="div-gpt-ad-1648018905638-0" />
                  </div>
                )}
                {/* {bannerData.length > 0 && ( */}
                {/* <a href={hpBanner?.url} target="_blank">
                  <img
                    width="300px"
                    height="250px"
                    src={hpBanner?.img}
                    alt="homepage banner"
                  />
                </a> */}

                {localStorage.getItem("contentRec") === "true" && (
                  <div className="pt-1 landing-ad desktop-only ">
                    <div
                      // path={`/1064661/HP_2nd_Right`}
                      // width={300}
                      // height={250}
                      id="div-gpt-ad-1648018826780-0"
                    />
                  </div>
                )}
              </Col>
              {/* </Row> */}
            </Row>

            <footer className="footer">
              <div className="toi-links">
                <a
                  href="https://eisamaygold.timesgroup.com/"
                  rel="noreferrer"
                  target="_blank"
                >
                  Eisamay Gold
                </a>
                <span className="toi-sep px-1"></span>
                <a href="mailto:help@eisamaygold.com" target="_blank">
                  Helpdesk
                </a>
                <span className="toi-sep px-1"></span>
                <Link
                  to="/eisamay/terms"
                  title="The Times of India — Mumbai"
                  target="_self"
                >
                  Terms of Use
                </Link>
                <span className="toi-sep px-1"></span>
                <Link
                  to="/eisamay/privacy"
                  title="The Times of India — Mumbai"
                  target="_self"
                >
                  Privacy and Cookie Policy
                </Link>
              </div>
              <div className="toi-copyright">
                Copyright © 2022 Bennett Coleman &amp; Co. Ltd. • All rights
                reserved.
              </div>
            </footer>
            {/* {localStorage.getItem("contentRec") === "true" && (
              <Row className="justify-content-center landing-bottom-ad ">
                <div className=" desktop-only ">
                  <div
                   
                    id="div-gpt-ad-1648018986998-0"
                  />
                </div>
              </Row>
            )} */}
          </div>
        </div>
      </div>
      <Modal
        isOpen={isOpenUpdate}
        size="sm"
        // className="mymodal-mob "
        overlayClassName={"myoverlay-update"}
        centered
        // toggle={function noRefCheck(){}}
      >
        {/* <ModalHeader>Modal title</ModalHeader> */}
        <ModalBody className="update-modal-card">
          <Col className="pr-1" style={{ textAlign: "end" }}>
            <X
              style={{ height: "20px", width: "20px", cursor: "pointer" }}
              onClick={() => {
                setIsOpenUpdate(false);
                bpnSubscribe(
                  "bpn_subscribe",
                  "bottombanner_cross",
                  "bottombanner_cross",
                  "bottombanner_cross"
                );
              }}
            />
          </Col>
          <Row className="">
            <Col sm="3" className="mt-2" style={{ textAlign: "center" }}>
              <span>
                <img
                  src="/eisamay-assets/logo/logo-main-eis.png"
                  width="auto"
                  height="36px"
                  alt="logo"
                />
              </span>
            </Col>

            <Col sm="9">
              <div className="content">
                <p>
                  Never miss an update, subscribe to our notifications now for
                  exclusive podcasts/stories.
                </p>
              </div>
              <Row
                style={{
                  justifyContent: window.innerWidth > 700 ? "start" : "center",
                }}
              >
                <Button
                  className=" btn-update-not mr-2"
                  onClick={() => {
                    bpnSubscribe(
                      "bpn_subscribe",
                      "bottombanner_notnow",
                      "bpn_subscribe",
                      "bottombanner_notnow"
                    );
                    setIsOpenUpdate(false);
                  }}
                >
                  Not Now
                </Button>
                <Button
                  className="btn-update-subs"
                  onClick={() => {
                    bpnSubscribe(
                      "bpn_subscribe",
                      "bottombanner_subscribe",
                      "bpn_subscribe",
                      "bottombanner_subscribe"
                    );
                    pushNotifications();
                    setIsOpenUpdate(false);
                  }}
                >
                  Subscribe
                </Button>
              </Row>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
}
