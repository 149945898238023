import React, {
  Fragment,
  useState,
  useEffect,
  useContext,
  useRef,
} from "react";
import axios from "axios";
import AppBar from "@material-ui/core/AppBar";
import RightFilterArchive from "./RightFilterArchive";
import { Card, CardBody, Row, Col, Dropdown } from "reactstrap";
import {
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  ListGroup,
  ListGroupItem,
  CardImg,
} from "reactstrap";
import JSCookie from "js-cookie";
import { AppContext } from "../contexts/AppContext";
import defaultImg from "../assets/images/default_image.png";
import moment from "moment";
import "./archive.css";
import TopHeader from "./TopHeader";
import { useHistory, Link } from "react-router-dom";
import {
  ArrowLeftCircle,
  ArrowRightCircle,
  ChevronLeft,
  ChevronRight,
  Filter,
  Search,
  X,
  Home,
  Calendar,
  BookOpen,
  Layers,
} from "react-feather";
import publicationJson from "../config/Publications.json";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Tooltip from "@material-ui/core/Tooltip";
import styled from "styled-components";

import Select from "react-select";
import { ItemImage } from "semantic-ui-react";
import DesktopSearchBar from "./DestopSearchBar";
import { isIOS } from "react-device-detect";
import { dateArchiveEvent } from "../functions/cleverTap";
const Nav = styled.div`
  background: #ffff;
  height: 57px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  // border-radius: 5px;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
  position: fixed;
  z-index: 999;
  width: -webkit-fill-available;
  left: 0px;
`;
function Archive(props) {
  const history = useHistory();
  const startingYear = 2021;
  const startingMonth = "December";
  // global state
  const {
    s3Source,
    getPublicationsData,
    themeColor,
    publicationsData,
    mostShared,
    pageClick,
    logo,
  } = useContext(AppContext);
  const [searchModal, toggleSearch] = useState(false);
  const [dateKeyData, setDateKeyData] = useState([]);
  const [filteredDateData, setFilteredDateData] = useState([]);
  const [isOpenPub, setIsOpenPub] = React.useState(false);
  const [isOpenEdition, setIsOpenEdition] = React.useState(false);
  const [isOpenDayOfWeek, setIsOpenDayOfWeek] = React.useState(false);
  const [isRegional, setIsRegional] = React.useState(false);
  const [dayIndexData, setDayIndexData] = useState([]);
  const [initialPublication, setInitialPublication] = React.useState({
    ...publicationJson.Publications[
      JSCookie.get("espubindex") ? Number(JSCookie.get("espubindex")) : 0
    ],
    index: JSCookie.get("espubindex") ? Number(JSCookie.get("espubindex")) : 0,
  });

  const [editionData, setEditionsData] = useState(
    publicationJson.Publications[
      JSCookie.get("espubindex") ? Number(JSCookie.get("espubindex")) : 0
    ].editions
  );
  const [initialEdition, setInitialEdition] = React.useState({
    ...publicationJson.Publications[
      JSCookie.get("espubindex") ? Number(JSCookie.get("espubindex")) : 0
    ].editions[
      JSCookie.get("espubindex") ? Number(JSCookie.get("esedindex")) : 0
    ],
    index: JSCookie.get("espubindex") ? Number(JSCookie.get("esedindex")) : 0,
  });
  const [images, setImages] = React.useState({});
  const [editions, seteditions] = useState([]);
  const [defaultPublication, setdefaultPublication] = useState({});

  const togglePublication = () => setIsOpenPub((prevState) => !prevState);
  const toggleEdition = () => setIsOpenEdition((prevState) => !prevState);
  const toggleDaysOfWeek = () => setIsOpenDayOfWeek((prevState) => !prevState);
  const [selectedWeekDay, setSelectedWeekDay] = useState("All");
  // const [currentYear, setCurrentYear] = useState(moment().format("YYYY"));
  const [currentYear, setCurrentYear] = useState(startingYear);
  const selectedYear = useRef(startingYear);
  const [thisYear, setThisYear] = useState(moment().format("YYYY"));
  const [selectedMonth, setSelectedMonth] = useState(moment().format("MMMM"));
  const [days, setDays] = useState([
    "All",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ]);
  const [Past2years, setPast2years] = useState([]);
  useEffect(() => {
    let Past2years = [];
    //will show only 3 years from now
    for (let i = Number(thisYear - 2); i < Number(thisYear); i++) {
      Past2years.push(i);
    }
    Past2years.push(Number(thisYear));
    setPast2years(Past2years);
  }, [thisYear]);
  const [dateIndexData, setDateIndexData] = useState([]);
  useEffect(() => {
    getDateIndex();
    // setDateIndexData()
  }, []);
  useEffect(() => {
    setImages({});
  }, [initialPublication, initialEdition, currentYear, selectedMonth]);
  const getDateIndex = (month) => {
    const endDate = new Date(currentYear, 11, 31);

    var a =
      currentYear > startingYear
        ? moment([endDate.getFullYear(), endDate.getMonth(), endDate.getDate()])
        : moment([startingYear, 11, 31]);

    var b =
      currentYear == startingYear
        ? moment([startingYear, 11, 1])
        : moment([
            currentYear,
            months.findIndex((v) => v == month ?? selectedMonth),
            1,
          ]);
    let diff = a.diff(b, "days"); // =1
    let array = [];
    array.push({
      Date: moment(
        new Date(
          currentYear == startingYear
            ? "2021-12-01"
            : `${currentYear}-${
                months.findIndex((v) => v == month ?? selectedMonth) + 1
              }-01`
        )
      ).format("DD-MM-YYYY"),
      Timestamp: new Date(
        moment(
          new Date(
            currentYear == startingYear
              ? "2021-12-01"
              : `${currentYear}-${
                  months.findIndex((v) => v == month ?? selectedMonth) + 1
                }-01`
          )
        ).format("YYYY-MM-DD")
      ).getTime(),
    });

    if (diff && diff !== "NaN") {
      for (let i = 0; i < diff; i++) {
        array.push({
          Date: moment(
            new Date(
              currentYear == startingYear
                ? "2021-12-01"
                : `${currentYear}-${
                    months.findIndex((v) => v == month ?? selectedMonth) + 1
                  }-01`
            )
          )
            .add(i + 1, "days")
            .format("DD-MM-YYYY"),
          Timestamp: new Date(
            moment(
              new Date(
                currentYear == startingYear
                  ? "2021-12-01"
                  : `${currentYear}-${
                      months.findIndex((v) => v == month ?? selectedMonth) + 1
                    }-01`
              )
            )
              .add(i + 1, "days")
              .format("YYYY-MM-DD")
          ).getTime(),
        });
      }
      setDateIndexData(array.reverse());
    }
  };
  useEffect(() => {
    setDateKeyData([]);
    setFilteredDateData([]);
    let array = [];
    if (dateIndexData && dateIndexData.length > 0) {
      for (let i = 0; i < dateIndexData.length; i++) {
        const ele = dateIndexData[i]?.Date?.split("-").reverse().join("-");
        array.push({
          date: ele,
          day: moment(new Date(ele)).format("dddd"),
          month: moment(new Date(ele)).format("MMMM"),
        });
      }
      setDateKeyData(array);
      setFilteredDateData(array);
    }
  }, [dateIndexData]);

  const dayOfTheWeekHandler = (weekDay) => {
    setSelectedWeekDay(weekDay);
    if (weekDay === "All") {
      setFilteredDateData([...dateKeyData]);
    } else {
      let filterByDay = dateKeyData.filter((v) => v.day === weekDay);
      setFilteredDateData(filterByDay);
    }
  };
  const monthHandler = (month) => {
    setSelectedMonth(month);
    let filterByMonth = dateKeyData.filter((v) => v.month === month);
    setFilteredDateData(filterByMonth);
  };
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 10,
    slidesToScroll: 1,
    nextArrow: (
      <div style={{ color: "black" }}>
        <ChevronRight />
      </div>
    ),
    prevArrow: <ChevronLeft style={{ stroke: "black" }} />,
  };

  const leftHandler = () => {
    if (Past2years.filter((v) => v == currentYear - 1)?.length > 0) {
      setCurrentYear(currentYear - 1);
    }
  };
  const rightHandler = () => {
    if (currentYear + 1 <= Number(moment().format("YYYY"))) {
      setCurrentYear(currentYear + 1);
    }
  };

  useEffect(() => {
    if (currentYear == startingYear) {
      monthHandler("December");
      getDateIndex();
    }
    if (currentYear < startingYear) {
      setSelectedMonth("");
      setFilteredDateData([]);
    }
    if (currentYear > startingYear) {
      async function updateData() {
        await setSelectedMonth("January");
        await getDateIndex("January");
      }
      updateData();
    }
  }, [currentYear]);

  const [currMonthIndex, setCurrMonthIndex] = React.useState(0);

  useEffect(() => {
    let currentMonth = moment().format("MMMM");
    setCurrMonthIndex(
      months.findIndex((v) => v.toLowerCase() === currentMonth.toLowerCase())
    );
  }, []);
  useEffect(() => {
    if (initialPublication.type === "image") {
      return setIsRegional(true);
    } else {
      return setIsRegional(false);
    }
  }, [initialPublication]);
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const handlePublication = async (publication, index) => {
    console.log("publication: ", publication);
    setInitialPublication({ ...publication, index });
    setInitialEdition({ ...publication.editions[0], index: 0 });
  };

  const handleEdition = async (edition, index) => {
    setInitialEdition({ ...edition, index });
  };

  const todayCurrentDate = moment(new Date()).format("YYYY-MM-DD");
  const redirectURL = `/${publicationsData?.[
    JSCookie.get("espubindex")
  ]?.publicationname
    ?.split(" ")
    .join("-")
    .toLowerCase()}/${publicationsData?.[JSCookie.get("espubindex")]?.editions[
    JSCookie.get("esedindex")
  ]?.editionname
    ?.split(" ")
    .join("-")
    .toLowerCase()}`;

  return (
    <Fragment>
      <TopHeader
        close={
          <div className="pl-3 pt-2 close-archive-modal">
            <Link
              to={redirectURL}
              style={{ textDecoration: "none", color: "red" }}
            ></Link>
          </div>
        }
      />

      <div
        className="archive-wrapper"
        style={{ height: window.innerHeight - 100, overflowY: "auto" }}
      >
        <Card>
          <Nav
            className="archive-nav"
            style={{ color: themeColor, backgroundColor: "white" }}
          >
            {/* {window.innerWidth > 1023 ? ( */}
            <div className="only-big-scrn-arc">
              <div
                // to={`/home`}
                style={{ textDecoration: "none", color: themeColor }}
              >
                <Col
                  className=" pl-4 cursor-pointer"
                  onClick={() => {
                    window.location.assign(
                      `${window.location.origin}/eisamay/home`
                    );
                  }}
                >
                  <Tooltip title="Homepage">
                    <Home className="icon-18" />
                  </Tooltip>
                  <div className="desktop-icon-name  ">বাড়ি</div>
                </Col>
              </div>
              {/* 
              <a to="#" style={{ textDecoration: "none", color: themeColor }}>
                <Col className=" pl-4 pr-0 cursor-pointer text-center">
                  <DesktopSearchBar
                    {...props}
                    currentPublication={initialPublication}
                    mostShared={mostShared}
                    themeColor={themeColor}
                    dateArray={JSCookie.get("eseddate")?.split("-")?.reverse()}
                    pageClick={pageClick}
                    defaultInitialEditionName={initialEdition?.editionid}
                    initialPublication={initialPublication}
                    dayIndexData={[]}
                    handleDate={() => {}}
                    fromArchive={true}
                  />

                  <div className="desktop-icon-name pt-1 ">SEARCH</div>
                </Col>
              </a> */}
              <a to="#" style={{ textDecoration: "none", color: themeColor }}>
                <Col
                  onClick={() => {}}
                  className=" pl-4 pr-0 cursor-pointer text-center"
                >
                  <Dropdown isOpen={isOpenDayOfWeek} toggle={toggleDaysOfWeek}>
                    <DropdownToggle
                      className="icon-choice d-flex justify-content-between align-items-center"
                      style={{ color: themeColor }}
                    >
                      <Calendar className="icon-18" />
                    </DropdownToggle>
                    <DropdownMenu
                      modifiers={{
                        setMaxHeight: {
                          enabled: true,
                          order: 890,
                          fn: (data) => {
                            return {
                              ...data,
                              styles: {
                                ...data.styles,
                                overflow: "auto",
                                maxHeight: "fit-content",
                                fontSize: "smaller",
                              },
                            };
                          },
                        },
                      }}
                    >
                      {days?.map((item, index) => {
                        return (
                          <DropdownItem
                            key={item + index}
                            value={item}
                            className="dropdown-archive-menu"
                            onClick={() => dayOfTheWeekHandler(item)}
                          >
                            {item}
                          </DropdownItem>
                        );
                      })}
                    </DropdownMenu>
                  </Dropdown>
                  <div className="desktop-icon-name  ">সপ্তাহ</div>
                </Col>
              </a>
              {/* <a to="#" style={{ textDecoration: "none", color: themeColor }}>
                <Col
                  onClick={() => {}}
                  className=" pl-4 pr-0 cursor-pointer text-center"
                >
                  <Dropdown isOpen={isOpenPub} toggle={togglePublication}>
                    <DropdownToggle
                      className="icon-choice d-flex justify-content-between align-items-center"
                      style={{ color: themeColor }}
                    >
                      <BookOpen className="icon-18" />
                    </DropdownToggle>
                    <DropdownMenu
                      modifiers={{
                        setMaxHeight: {
                          enabled: true,
                          order: 890,
                          fn: (data) => {
                            return {
                              ...data,
                              styles: {
                                ...data.styles,
                                overflow: "auto",
                                maxHeight: "fit-content",
                                fontSize: "smaller",
                              },
                            };
                          },
                        },
                      }}
                    >
                      {publicationJson?.Publications?.map(
                        (publication, index) => {
                          return (
                            <DropdownItem
                              key={publication.id}
                              className="dropdown-archive-menu"
                              value={publication?.publicationname}
                              // className="dropdown-item"
                              onClick={() => {
                                handlePublication(publication, index);
                                setEditionsData(publication.editions);
                              }}
                            >
                              {publication.publicationname}
                            </DropdownItem>
                          );
                        }
                      )}
                    </DropdownMenu>
                  </Dropdown>
                  <div className="desktop-icon-name  ">PUBLICATION</div>
                </Col>
              </a> */}
              <a to="#" style={{ textDecoration: "none", color: themeColor }}>
                <Col className=" pl-4 pr-0 cursor-pointer text-center">
                  <Dropdown isOpen={isOpenEdition} toggle={toggleEdition}>
                    <DropdownToggle
                      className="icon-choice d-flex justify-content-between align-items-center"
                      style={{ color: themeColor }}
                    >
                      <Layers className="icon-18" />
                    </DropdownToggle>
                    <DropdownMenu
                      modifiers={{
                        setMaxHeight: {
                          enabled: true,
                          order: 890,
                          fn: (data) => {
                            return {
                              ...data,
                              styles: {
                                ...data.styles,
                                overflow: "auto",
                                maxHeight: "fit-content",
                                fontSize: "smaller",
                              },
                            };
                          },
                        },
                      }}
                    >
                      {editionData?.map((item, index) => {
                        return (
                          <DropdownItem
                            key={item.editionname + index}
                            // value={item.editionname}
                            className="dropdown-archive-menu"
                            onClick={() => handleEdition(item, index)}
                          >
                            {item?.editionnameBeng}
                          </DropdownItem>
                        );
                      })}
                    </DropdownMenu>
                  </Dropdown>
                  <div className="desktop-icon-name  ">সংস্করণ</div>
                </Col>
              </a>
            </div>
            <>
              <div
                className="only-small-scrn-arc"
                // to={`/home`}
                style={{ textDecoration: "none", color: themeColor }}
              >
                <Col
                  className=" pl-4 cursor-pointer"
                  onClick={() => {
                    window.location.assign(
                      `${window.location.origin}/eisamay/home`
                    );
                  }}
                >
                  <Tooltip title="Homepage">
                    <Home className="icon-18" />
                  </Tooltip>
                  {/* <div className="desktop-icon-name  ">HOME</div> */}
                </Col>
              </div>
              {/* {window.innerWidth < 1024 && (
                <a to="#" style={{ textDecoration: "none", color: themeColor }}>
                  <Col className="pr-0 cursor-pointer text-center pb-1">
                    <DesktopSearchBar
                      {...props}
                      currentPublication={initialPublication}
                      mostShared={mostShared}
                      themeColor={themeColor}
                      dateArray={JSCookie.get("eseddate")?.split("-")?.reverse()}
                      pageClick={pageClick}
                      defaultInitialEditionName={initialEdition?.editionid}
                      initialPublication={initialPublication}
                      dayIndexData={[]}
                      handleDate={() => {}}
                      fromArchive={true}
                    />
                  </Col>
                </a>
              )} */}
            </>
            <div className="icon-center-archive">
              {/* <p className="m-0">{initialPublication?.publicationnameBengla}</p> */}
              <b className="m-0">{initialEdition?.editionnameBeng}</b>
            </div>
            <Col md="4" className="only-big-scrn-arc-dates">
              <div className="year_lists">
                <div className="data-contents">
                  <ul className="w-100 text-center">
                    {Past2years.map((year, index) => {
                      return (
                        <li
                          onClick={() => {
                            selectedYear.current = year;
                            setCurrentYear(year);
                          }}
                          style={{
                            color:
                              selectedYear.current === year ? "white" : "grey",
                            backgroundColor:
                              selectedYear.current === year
                                ? themeColor
                                : "white",
                          }}
                          key={"years" + year}
                        >
                          {year}
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
              <Row className="justify-content-center">
                {months &&
                  months?.map((month, index) => {
                    return (
                      <ListGroupItem
                        onClick={() => {
                          monthHandler(month);
                        }}
                        className="border-0 month_items-archive cursor-pointer"
                        style={{
                          color: selectedMonth === month && themeColor,
                          textDecoration:
                            selectedMonth === month && "underline",

                          margin: "0px",
                          fontSize: "samller !mportant",
                        }}
                      >
                        {month?.substring(0, 3)}
                      </ListGroupItem>
                    );
                  })}
              </Row>
            </Col>
            <div className="pr-4 only-samll-scrn-filtr">
              <RightFilterArchive
                Past2years={Past2years}
                selectedYear={selectedYear}
                setCurrentYear={setCurrentYear}
                themeColor={themeColor}
                months={months}
                startingYear={startingYear}
                currentYear={currentYear}
                currMonthIndex={currMonthIndex}
                monthHandler={monthHandler}
                selectedMonth={selectedMonth}
                togglePublication={togglePublication}
                handlePublication={handlePublication}
                setEditionsData={setEditionsData}
                isOpenPub={isOpenPub}
                editionData={editionData}
                handleEdition={handleEdition}
                isOpenEdition={isOpenEdition}
                toggleEdition={toggleEdition}
                initialEdition={initialEdition}
                initialPublication={initialPublication}
              />
            </div>
          </Nav>
        </Card>
        <Row className="arc-container ml-4">
          <Col className="px-0 ">
            <Row>
              {filteredDateData &&
                filteredDateData.length > 0 &&
                filteredDateData.map((item, index) => {
                  let date1 = item.date.split("-").join("/");
                  let date2 = item.date.split("-").reverse().join("-");
                  if (!images?.[date1])
                    return (
                      <>
                        {todayCurrentDate !== item.date && (
                          <Col
                            md="4"
                            lg="3"
                            xl="2"
                            sm="4"
                            xs="4"
                            className="mb-1 px-0"
                            key={index + item.date}
                            onClick={async () => {
                              dateArchiveEvent(
                                "date_archive",
                                initialEdition?.editionname,
                                "date_archive",
                                item?.date
                              );
                              if (!images?.[date1]) {
                                await JSCookie.set(
                                  "espubindex",
                                  initialPublication.index,
                                  { expires: 3 }
                                );
                                await JSCookie.set(
                                  "esedindex",
                                  initialEdition.index,
                                  { expires: 3 }
                                );
                                await JSCookie.set("eseddate", date2);
                                await JSCookie.set("fromArchive", true);
                                await getPublicationsData();
                                history.push(
                                  `/${initialPublication.publicationname
                                    .toLowerCase()
                                    .split(" ")
                                    .join("-")}/${initialEdition?.editionname
                                    .toLowerCase()
                                    .split(" ")
                                    .join("-")}`
                                );
                              }
                            }}
                            id={item.date}
                          >
                            {filteredDateData &&
                              filteredDateData.length > 0 && (
                                <Card
                                  className=" image_card_archive"
                                  style={{
                                    cursor: images?.[date1]
                                      ? "not-allowed"
                                      : "pointer",
                                    width: "85%",
                                    maxHeight:
                                      initialPublication.publicationname ===
                                        "Mirror" && window.innerWidth < 1200
                                        ? "169px"
                                        : initialPublication.publicationname ===
                                            "Mirror" && window.innerWidth > 2000
                                        ? "330px"
                                        : initialPublication.publicationname ===
                                            "Mirror" && window.innerWidth > 1800
                                        ? "240px"
                                        : initialPublication.publicationname ===
                                            "Mirror" && window.innerWidth > 1600
                                        ? "220px"
                                        : initialPublication.publicationname ===
                                            "Mirror" && window.innerWidth > 1200
                                        ? "160px"
                                        : "auto",
                                  }}
                                >
                                  {filteredDateData?.length > 0 && (
                                    <CardImg
                                      style={
                                        {
                                          //   // maxWidth: "199px",
                                          // maxHeight: "275px",
                                        }
                                      }
                                      onError={(e) => {
                                        e.target.src = defaultImg;
                                        setImages({
                                          ...images,
                                          [date1]: true,
                                        });
                                      }}
                                      src={`${s3Source}/PublicationData/${initialPublication?.publicationcode}/${initialEdition?.editionid}/${date1}/TodaysPage/todaysthumbimage_${initialEdition?.editionid}.jpg`}
                                    />
                                  )}

                                  {/* <div
                                    className="date-font-archive text-white text-center"
                                    style={{
                                      borderRadius: "0px 0px 5px 5px",
                                      backgroundColor: themeColor,
                                      padding: " 0px 4px",
                                    }}
                                  >
                                    {item.date}
                                  </div> */}

                                  <div
                                    className="card_footer-arch"
                                    style={
                                      {
                                        // backgroundColor: "black",
                                      }
                                    }
                                  >
                                    <span>{item.date}</span>
                                  </div>
                                </Card>
                              )}
                          </Col>
                        )}
                      </>
                    );
                })}
            </Row>
          </Col>
        </Row>
      </div>
    </Fragment>
  );
}
export default Archive;
