import React, { useEffect, useContext } from "react";
import "./App.css";
import { Switch, BrowserRouter, Route } from "react-router-dom";
import { authProtectedRoutes, publicRoutes } from "./routes/";
import AppRoute from "./routes/route";
import NotFound from "./components/NotFound";
import jsonp from "jsonp";
import { setTicketId } from "./helper";
import { activeDaysEvents, loginEvents } from "./functions/cleverTap";
import JSCookie from "js-cookie";
import { Helmet } from "react-helmet";
import { AppContext } from "./contexts/AppContext";
import dayjs from "dayjs";
function App() {
  const {
    validateCustomer,
    loggedInUser,
    setLoggedInUser,
    initialEdition,
    initialPublication,
    currentPage,
    selectedNewsType,
    setUserLoggedOut,
    // articleViewData,
  } = useContext(AppContext);
  if (window.location.pathname === "/") {
    window.location.assign(`${window.location.origin}/eisamay/home`);
  }

  const loginCompleteEvent = (data) => {
    loginEvents(
      "Login_Completed",
      "Login_Completed",
      "Login_Completed",
      "topbar"
    );
  };
  //gtm and cleverTap

  useEffect(() => {
    const checkSSOTicketId = (ev) => {
      if (
        ev?.target?.closest("#previous-button") ||
        ev?.target?.closest("#next-button")
      ) {
        return;
      }

      jsonp(
        `https://jsso.indiatimes.com/sso/crossdomain/getTicket?channel=${
          process.env.REACT_APP_CHANNEL_SSO_NAME
        }&version=v1&t=${Date.now()}`,
        null,
        function async(err, data) {
          if (err) {
          } else {
            const temp_ticketId = localStorage.getItem("temp_ticketId");
            if ((data.status && data.ticketId) || temp_ticketId) {
              let ssoTicketId = data.ticketId || temp_ticketId;

              const url = `https://jsso.indiatimes.com/sso/crossdomain/v1validateTicket?channel=${process.env.REACT_APP_CHANNEL_SSO_NAME}&ticketId=${ssoTicketId}`;
              jsonp(url, null, function (err, data) {
                if (err) {
                  console.log(err);
                } else {
                  validateCustomer(ssoTicketId, data);
                  setLoggedInUser(
                    ["403", "401"].includes(data?.code) ? null : data
                  );
                  setUserLoggedOut(
                    ["403", "401"].includes(data?.code) ? true : false
                  );
                  // loginCompleteEvent(data);
                  setTicketId(ssoTicketId);
                }
                // gtm and cleverTap
                let active_day = localStorage.getItem("active_day");
                let today = new Date();
                if (
                  active_day == undefined ||
                  dayjs(today).isAfter(dayjs(active_day), "day")
                ) {
                  window?.clevertap?.event?.push("active_days", {
                    // event: "active_days",
                    eventCategory: "active_days",
                    eventAction: "active_days",
                    eventLabel: "active_days",
                    TOC: currentPage?.PageTitle,
                    //required
                    PAGE_NO: currentPage?.pageNumber,
                    //required
                    epaperType: selectedNewsType,
                    // title: articleViewData?.ArticleTitle,
                    // author: articleViewData?.ArticleAuthor,
                    publication: initialPublication?.publicationname,
                    edition: initialEdition?.editionname,
                    articleUrl: window.location.href,
                  });

                  window.gtag("event", "active_days", {
                    // event: "active_days",
                    eventCategory: "active_days",
                    eventAction: "active_days",
                    eventLabel: "active_days",
                    TOC: currentPage?.PageTitle,
                    //required
                    PAGE_NO: currentPage?.pageNumber,
                    //required
                    epaperType: selectedNewsType,
                    // title: articleViewData?.ArticleTitle,
                    // author: articleViewData?.ArticleAuthor,
                    publication: initialPublication?.publicationname,
                    edition: initialEdition?.editionname,
                    articleUrl: window.location.href,
                  });

                  localStorage.setItem("active_day", new Date());
                }
              });
            } else {
              setUserLoggedOut(true);

              let active_day = localStorage.getItem("active_day");
              let today = new Date();
              if (
                active_day == undefined ||
                dayjs(today).isAfter(dayjs(active_day), "day")
              ) {
                window?.clevertap?.event?.push("active_days", {
                  // event: "active_days",
                  eventCategory: "active_days",
                  eventAction: "active_days",
                  eventLabel: "active_days",
                  TOC: currentPage?.PageTitle,
                  //required
                  PAGE_NO: currentPage?.pageNumber,
                  //required
                  epaperType: selectedNewsType,
                  // title: articleViewData?.ArticleTitle,
                  // author: articleViewData?.ArticleAuthor,
                  publication: initialPublication?.publicationname,
                  edition: initialEdition?.editionname,
                  articleUrl: window.location.href,
                });
                window.gtag("event", "active_days", {
                  // event: "active_days",
                  eventCategory: "active_days",
                  eventAction: "active_days",
                  eventLabel: "active_days",
                  TOC: currentPage?.PageTitle,
                  //required
                  PAGE_NO: currentPage?.pageNumber,
                  //required
                  epaperType: selectedNewsType,
                  // title: articleViewData?.ArticleTitle,
                  // author: articleViewData?.ArticleAuthor,
                  publication: initialPublication?.publicationname,
                  edition: initialEdition?.editionname,
                  articleUrl: window.location.href,
                });

                localStorage.setItem("active_day", new Date());
              }
            }
          }
        }
      );
    };
    checkSSOTicketId();
    if (document) document?.addEventListener("click", checkSSOTicketId);
  }, []);
  return (
    <>
      <Helmet>{/* <meta name="robots" content="noindex" /> */}</Helmet>
      <BrowserRouter>
        <Switch>
          {publicRoutes.map((route, idx) => (
            <AppRoute
              path={route.path}
              component={route.component}
              key={idx}
              isAuthProtected={false}
            />
          ))}
          {authProtectedRoutes.length > 0 &&
            authProtectedRoutes.map((route, idx) => (
              <AppRoute
                path={route.path}
                component={route.component}
                key={idx}
                isAuthProtected={true}
              />
            ))}
          <Route component={(props) => <NotFound {...props} />} />
        </Switch>
      </BrowserRouter>
    </>
  );
}
export default App;
