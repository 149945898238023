import React, { useContext } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import { useHistory, Redirect } from "react-router-dom";
import JSCookie from "js-cookie";
import List from "@material-ui/core/List";
import NotificationsNoneOutlinedIcon from "@material-ui/icons/NotificationsNoneOutlined";
import ListItem from "@material-ui/core/ListItem";
import QuestionAnswerIcon from "@material-ui/icons/QuestionAnswer";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import publicationJson from "../config/Publications.json";

import {
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  ListGroup,
  ListGroupItem,
  Col,
  CardImg,
  Dropdown,
  Input,
} from "reactstrap";
import "../components/sidebar.css";
import clip_icon from "../assets/icons/clip_icon.png";
import CropIcon from "@material-ui/icons/Crop";
import { getCustomer } from "../helper";
import axios from "axios";
import { Filter, BookOpen } from "react-feather";
import LiveHelpIcon from "@mui/icons-material/LiveHelp";
import FileSaver from "file-saver";
import { AppContext } from "../contexts/AppContext";

const useStyles = makeStyles({
  list: {
    width: 250,
    backgroundColor: "white",
  },
  fullList: {
    width: "auto",
  },
});

export default function RightFilterArchive({
  props,
  handlePublication,

  selectedYear,
  Past2years,
  setCurrentYear,
  themeColor,
  months,
  startingYear,
  currentYear,
  currMonthIndex,
  monthHandler,
  selectedMonth,
  togglePublication,
  isOpenPub,
  setEditionsData,
  editionData,
  handleEdition,
  isOpenEdition,
  initialEdition,
  toggleEdition,
  initialPublication,
}) {
  // global state
  // const {  } = useContext(AppContext);
  const classes = useStyles();
  const [state, setState] = React.useState({
    // top: false,
    // left: false,
    // bottom: false,
    right: false,
  });
  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor, handlePublication) => {
    console.log("handlePublication: ", handlePublication);
    return (
      <div
        className={clsx(classes.list, {
          [classes.fullList]: anchor === "top" || anchor === "bottom",
        })}
        role="presentation"
        // onClick={toggleDrawer(anchor, false)}
        // onKeyDown={toggleDrawer(anchor, false)}
      >
        <div className="bg-light ">
          <h6
            className="font-weight-bold   pl-4 py-3 my-0 font-sanserf"
            style={{ color: themeColor }}
          >
            বছর নির্বাচন করুন
          </h6>
        </div>
        <List className="text-uppercase  ">
          <ListItem button>
            <ListItemIcon>
              {Past2years.map((year, index) => {
                return (
                  <b
                    className="right-ar-year"
                    onClick={() => {
                      selectedYear.current = year;
                      setCurrentYear(year);
                    }}
                    style={{
                      color: selectedYear.current === year ? "white" : "grey",
                      backgroundColor:
                        selectedYear.current === year ? themeColor : "white",
                    }}
                    key={"years" + year}
                  >
                    {year}
                  </b>
                );
              })}
            </ListItemIcon>
          </ListItem>
        </List>

        <div className="bg-light">
          <h6
            className="font-weight-bold  mb-0 pl-4 py-3 font-sanserf"
            style={{ color: themeColor }}
          >
            মাস নির্বাচন করুন
          </h6>
        </div>
        <List
          className="text-uppercase"
          style={{ overflow: "auto", display: "flex" }}
        >
          <ListItem button>
            <ListItemIcon
              style={{
                maxWidth: "205px",
                overflowX: "hidden",
                flexWrap: "wrap",
              }}
            >
              {months &&
                months?.map((month, index) => {
                  return (
                    <ListGroupItem
                      onClick={() => {
                        monthHandler(month);
                      }}
                      className=" month_items-ar"
                      style={{
                        textDecoration: "none",
                        backgroundColor:
                          selectedMonth === month ? "grey" : "white",
                        border: " 1px solid grey !important",
                        borderRadius: "3px",
                        cursor:
                          startingYear < currentYear
                            ? index > currMonthIndex
                              ? "not-allowed"
                              : "pointer"
                            : startingYear == currentYear
                            ? month !== "December"
                              ? "not-allowed"
                              : "pointer"
                            : currentYear < startingYear && "not-allowed",
                        margin: "3px 0px",
                        fontSize: "medium !mportant",
                      }}
                    >
                      {month?.substring(0, 3)}
                    </ListGroupItem>
                  );
                })}
            </ListItemIcon>
          </ListItem>
        </List>

        {/* <div className="bg-light">
          <h6
            className="font-weight-bold   pl-4 py-3 font-sanserf"
            style={{ color: themeColor }}
          >
            PUBLICATIONS
          </h6>
        </div> */}

        {/* <div className="btn-group">
          <button
            className="chaoice-inner-archive-right dropdown-toggle d-flex justify-content-between align-items-center"
            style={{ width: "192px" }}
            type="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            {initialPublication?.publicationname}
          </button>
          <div className="dropdown-menu">
            {publicationJson?.Publications?.map((publication, index) => {
              return (
                <div
                  onClick={(e) => {
                    handlePublication(publication, index);
                    setEditionsData(publication.editions);
                  }}
                  className="dropdown-item"
                >
                  {publication.publicationname}
                </div>
              );
            })}
          </div>
        </div> */}
        {/* 
        <div className="bg-light">
          <h6
            className="font-weight-bold   pl-4 py-3 font-sanserf"
            style={{ color: themeColor }}
          >
            সংস্করণ
          </h6>
        </div>

        <div className="btn-group">
          <button
            // className="  btn btn-secondary btn-lg dropdown-toggle chaoice-inner-archive-right "

            className="chaoice-inner-archive-right dropdown-toggle d-flex justify-content-between align-items-center"
            style={{ width: "192px" }}
            type="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            {initialEdition?.editionnameBeng}
          </button>
          <div className="dropdown-menu">
            {editionData?.map((item, index) => {
              return (
                <div
                  key={item.editionname + index}
                  onClick={() => handleEdition(item, index)}
                  className="dropdown-item"
                >
                  {item?.editionnameBeng}
                </div>
              );
            })}
          </div>
        </div> */}
      </div>
    );
  };

  return (
    <div>
      {["right"].map((anchor) => (
        <React.Fragment key={anchor}>
          <Filter
            className="icon-18"
            style={{ color: themeColor }}
            onClick={toggleDrawer(anchor, true)}
          />
          <Drawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
          >
            {list(anchor, handlePublication)}
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  );
}
