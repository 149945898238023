import React, { useContext } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import { useHistory, Redirect } from "react-router-dom";
import JSCookie from "js-cookie";
import List from "@material-ui/core/List";
import NotificationsNoneOutlinedIcon from "@material-ui/icons/NotificationsNoneOutlined";
import ListItem from "@material-ui/core/ListItem";
import QuestionAnswerIcon from "@material-ui/icons/QuestionAnswer";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import DehazeIcon from "@material-ui/icons/Dehaze";
import PhotoLibraryIcon from "@material-ui/icons/PhotoLibrary";
import ReceiptOutlinedIcon from "@material-ui/icons/ReceiptOutlined";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import HelpOutlineOutlinedIcon from "@material-ui/icons/HelpOutlineOutlined";
import PrintIcon from "@material-ui/icons/Print";
import ShareIcon from "@material-ui/icons/Share";
import BookmarkBorderIcon from "@material-ui/icons/BookmarkBorder";
import "../components/sidebar.css";
import clip_icon from "../assets/icons/clip_icon.png";
import CropIcon from "@material-ui/icons/Crop";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { getCustomer } from "../helper";
import jsonp from "jsonp";
import axios from "axios";
import { LogOut, Scissors, Download, LogIn } from "react-feather";
import LiveHelpIcon from "@mui/icons-material/LiveHelp";
import FileSaver from "file-saver";
import { AppContext } from "../contexts/AppContext";
import {
  downloadEvent,
  loginEvents,
  myAccountEvent,
  signOutEvent,
} from "../functions/cleverTap";

const useStyles = makeStyles({
  list: {
    width: 250,
    backgroundColor: "white",
  },
  fullList: {
    width: "auto",
  },
  drawerPaper: {
    zIndex: 9999,
  },
});

export default function RightDrawer(props) {
  // global state
  const {
    initialPublication,
    initialEdition,
    todaysDate,
    dateArray,
    s3Source,
    currentPage,
    filteredDate,
    todayCurrentDate,
    fit2page,
    isOpenContactModal,
    setIsOpenContactModal,
  } = useContext(AppContext);

  const classes = useStyles();
  const [state, setState] = React.useState({
    // top: false,
    // left: false,
    // bottom: false,
    right: false,
  });
  const [loggedOut, setLoggedOut] = React.useState(false);
  const history = useHistory();
  const handleClick = () => history.push(`/gallery-view`);
  const handleClickReplica = () =>
    history.push(
      `/${props.publicationsData[JSCookie.get("espubindex")]?.publicationname
        ?.split(" ")
        .join("-")
        .toLowerCase()}/${props.publicationsData[
        JSCookie.get("espubindex")
      ]?.editions[JSCookie.get("esedindex")]?.editionname
        ?.split(" ")
        .join("-")
        .toLowerCase()}`
    );
  const handleClickDigest = () => history.push(`/digest-view`);

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };
  function saveMainImg() {
    // var blob = new Blob(["Hello, world!"], {type: "text/plain;charset=utf-8"});
    FileSaver.saveAs(
      `${s3Source}/PublicationData/${initialPublication?.publicationcode}/${
        initialEdition?.editionid
      }/${dateArray?.join("/")}/Page/${currentPage?.PageName}.jpg?v=${
        filteredDate?.Timestamp
      }`,
      `${currentPage?.PageTitle}.jpg`
    );
  }
  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === "top" || anchor === "bottom",
      })}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List className="text-uppercase  ">
        <ListItem
          button
          onClick={() => {
            myAccountEvent("my_account", "my_account", "my_account", "header");
            window.open("https://eisamaygold.timesgroup.com/myaccount");
          }}
        >
          <ListItemIcon>
            <AccountCircleIcon style={{ color: props.themeColor }} />
          </ListItemIcon>
          <ListItemText primary={"আমার অ্যাকাউন্ট"} />
        </ListItem>
        {/* <ListItem button>
          <ListItemIcon>
            <NotificationsNoneOutlinedIcon
              style={{ color: props.themeColor }}
            />
          </ListItemIcon>
          <ListItemText primary={"NOTIFICATIONS"} />
        </ListItem> */}
        {/* <ListItem button>
          <ListItemIcon>
            <BookmarkBorderIcon style={{ color: props.themeColor }} />
          </ListItemIcon>
          <ListItemText primary={"MY BOOKMARK"} />
        </ListItem> */}
      </List>
      {/* <Divider /> */}
      {/* <div className="bg-light">
        <h5
          className="font-weight-bold   pl-4 py-1"
          style={{ color: props.themeColor }}
        >
          VIEW
        </h5>
      </div> */}
      {/* <List className="text-uppercase  ">
        <ListItem button onClick={handleClickReplica}>
          <ListItemIcon>
            <ReceiptOutlinedIcon style={{ color: props.themeColor }} />
          </ListItemIcon>
          <ListItemText primary={"Print Replica"} />
        </ListItem>
        {!props.isRegional && (
          <ListItem button onClick={handleClick}>
            <ListItemIcon>
              <PhotoLibraryIcon style={{ color: props.themeColor }} />
            </ListItemIcon>
            <ListItemText primary={"Gallery"} />
          </ListItem>
        )}
        <ListItem button onClick={handleClickDigest}>
          <ListItemIcon>
            <DehazeIcon style={{ color: props.themeColor }} />
          </ListItemIcon>
          <ListItemText primary={"DIGEST"} />
        </ListItem>
      </List> */}
      {/* <Divider /> */}
      {/* <div className="bg-light">
        <h5
          className="font-weight-bold  pl-4 py-1"
          style={{ color: props.themeColor }}
        >
          DOWNLOAD
        </h5>
      </div> */}
      {/* <List className="text-uppercase  ">
        {["Clip Page", "Crop As PDF"].map((text, index) => (
          <ListItem button key={text}>
            <ListItemIcon>
              {index === 0 ? (
                // <BookmarkBorderIcon className="text-icon-color" />

                // <img src={clip_icon} height="25px" alt="clip page" />
                <Scissors style={{ color: props.themeColor }} />
              ) : (
                <CropIcon style={{ color: props.themeColor }} />
              )}
            </ListItemIcon>
            <ListItemText primary={text} />
          </ListItem>
        ))}
      </List> */}
      {/* <Divider /> */}
      <div className="bg-light">
        <h5
          className="font-weight-bold   pl-4 py-1"
          style={{ color: props.themeColor }}
        >
          অপশন
        </h5>
      </div>
      <List className="text-uppercase    ">
        {/* <ListItem button>
          <ListItemIcon>
            <PrintIcon style={{ color: props.themeColor }} />
          </ListItemIcon>
          <ListItemText primary={"Print "} />
        </ListItem> */}
        {/* <ListItem button>
          <ListItemIcon>
            <ShareIcon style={{ color: props.themeColor }} />
          </ListItemIcon>
          <ListItemText primary={"SHARE"} />
        </ListItem> */}
        {/* <ListItem button>
          <ListItemIcon>
            <HelpOutlineOutlinedIcon style={{ color: props.themeColor }} />
          </ListItemIcon>
          <ListItemText primary={"NAVIGATE"} />
        </ListItem> */}
        {todayCurrentDate !== todaysDate && !fit2page && (
          <ListItem
            button
            onClick={() => {
              downloadEvent("download", "download", "download", "header");
              saveMainImg();
            }}
          >
            <ListItemIcon>
              <Download style={{ color: props.themeColor }} />
            </ListItemIcon>
            <ListItemText primary={"ডাউনলোড"} />
          </ListItem>
        )}
        <ListItem
          button
          onClick={() => {
            // setIsOpenContactModal(!isOpenContactModal);
            window.open("mailto:help@eisamaygold.com");
          }}
        >
          <ListItemIcon>
            <QuestionAnswerIcon style={{ color: props.themeColor }} />
          </ListItemIcon>
          <ListItemText primary={"যোগাযোগ"} />
        </ListItem>
        {/* <ListItem
          button
          onClick={() => {
            window.open("/faqpage");
          }}
        >
          <ListItemIcon>
            <LiveHelpIcon style={{ color: props.themeColor }} />
          </ListItemIcon>
          <ListItemText primary={"FAQ's"} />
        </ListItem> */}
        {localStorage.getItem("customer_details") ? (
          <ListItem
            button
            onClick={() => {
              signOutEvent(
                "signout_click",
                "signout",
                "signout_click",
                "header"
              );
              handleLogout();
            }}
          >
            <ListItemIcon>
              <LogOut style={{ color: props.themeColor }} />
            </ListItemIcon>
            <ListItemText primary={"প্রস্থান"} />
          </ListItem>
        ) : (
          <ListItem
            button
            onClick={() => {
              loginEvents(
                "login_initiated",
                "login_initiated",
                "login_initiated",
                "topbar"
              );
              localStorage.setItem("login_source", "hamburger");

              handleLogin();
            }}
          >
            <ListItemIcon>
              <LogIn style={{ color: props.themeColor }} />
            </ListItemIcon>
            <ListItemText primary={"প্রবেশ করুন"} />
          </ListItem>
        )}
      </List>
    </div>
  );

  const handleLogout = () => {
    window.location.reload();
    let customer_details = localStorage.getItem("customer_details");
    if (customer_details) customer_details = JSON.parse(customer_details);
    axios
      .delete(
        `${process.env.REACT_APP_SUBSCRIPTION_URL}/customer/logout/${customer_details?.id}`
      )
      .then((res) => {
        console.log("logged out");
      });
    let prev_localstorage = localStorage.getItem("active_day");
    localStorage.clear();
    localStorage.setItem("active_day", prev_localstorage);
    JSCookie.remove("token");
    setLoggedOut(true);
    jsonp(
      `https://jsso.indiatimes.com/sso/identity/profile/logout/external?channel=${process.env.REACT_APP_CHANNEL_SSO_NAME}`,
      null,
      function async(err, data) {
        if (err) {
          console.error(err.message);
        } else {
        }
      }
    );
  };
  const handleLogin = () => {
    window.open(
      `https://jsso.indiatimes.com/sso/identity/login?channel=${process.env.REACT_APP_CHANNEL_SSO_NAME}&ru=${window.location.origin}/eisamay/jsso`,
      "Ratting",
      "width=600,height=600,toolbar=0,status=0,"
    );
  };

  return (
    <div>
      {/* {loggedOut && <Redirect to="/login" />} */}
      {["right"].map((anchor) => (
        <React.Fragment key={anchor}>
          <MoreVertIcon
            style={{ color: props.themeColor }}
            onClick={toggleDrawer(anchor, true)}
          />
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
          >
            {list(anchor)}
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  );
}
