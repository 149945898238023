import React, { useState, useContext, useEffect, useRef, useMemo } from "react";
import { Row, Col, Card, TabContent, TabPane } from "reactstrap";
import { Nav, NavItem, NavLink, CardImg, Button, Spinner } from "reactstrap";
import JSCookie from "js-cookie";
import "../../src/index.css";
import ArticleModal from "./ArticleModal";
import { AppContext } from "../contexts/AppContext";
import DatePicker from "react-datepicker";
import { useHistory, Link } from "react-router-dom";
import card_mob from "./card_mob.png";
import card_im2 from "../assets/images/Image From ET__UIww.png";
import card_im1 from "../assets/images/Image From ET__UI.png";
import card_im3 from "../assets/images/Image From ET__UI1.png";
import axios from "axios";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import classnames from "classnames";
import Radio from "@material-ui/core/Radio";
import Checkbox from "@material-ui/core/Checkbox";

import "./desktopsearchbar.css";
import { Search, XCircle } from "react-feather";
import ReactModal from "react-modal";
import moment from "moment";
import Tooltip from "@material-ui/core/Tooltip";
import ReactArticleModal from "react-modal";
ReactArticleModal.setAppElement("#root");

export default function DesktopSearchBar(props) {
  const {
    s3Source,
    setImageUrl,
    setActiveTabName,
    publicationJson,
    getPublicationsData,
  } = useContext(AppContext);
  const [searchValue, setSearchValue] = useState({
    search: "",
    from: "",
    articleName: "",
  });
  const history = useHistory();

  const [allEditions, setAllEditions] = useState(false);

  const handleCheckboxChange = (event) => {
    setAllEditions(event.target.checked);
  };
  const [advertisement, setAdvertisement] = useState("");
  const [isOpenArticleModal, setIsOpenArticleModal] = useState(false);
  const [isOpen, setIsOpen] = React.useState(false);
  const [isOpenSearchResult, setIsOpenSearchResult] = useState(false);
  const [currentPage, setCurrentPage] = useState({});
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(
    new Date(moment().add(1, "days").format("YYYY-MM-DD"))
  );
  const [selectedValue, setSelectedValue] = React.useState("today");
  const [searchQuery, setSearchQuery] = useState("");
  const [selected_startDate, set_selected_startDate] = useState(
    moment(startDate).format("Do MMM, YYYY")
  );

  const [selected_endDate, set_selected_endDate] = useState(
    moment(endDate).format("Do MMM, YYYY")
  );
  const [posts, setPosts] = useState([]);
  const [currentArticleCount, setCurrentArticleCount] = useState(0);
  const [totalFoundArticles, setTotalFoundArticles] = useState(0);
  const [sort, selectedSort] = useState("relevancy");
  const [lastSearch, setLastSearch] = useState({});
  const [loading, setLoading] = useState(false);
  const [publication, setPublication] = useState({});
  const [edition, setEdition] = useState({});
  const [dateArray, setDateArray] = useState({});
  function toggleModal() {
    setIsOpen(!isOpen);
  }
  const openSearchResult = () => {
    setIsOpenSearchResult(true);
  };
  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const [activeTab, setActiveTab] = React.useState("article");
  const toggle = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
      setActiveTabName(tab);
    }

    setCurrentArticleCount(0);
    setPosts([]);
  };
  useEffect(() => {
    onSearchSubmit();
  }, [activeTab, selectedValue, sort]);

  let desktopView = window.innerWidth > 700;

  const onChangeInput = (event) => {
    setSearchQuery(
      event.target.value.replace(/[^\w\s\_]/gi, "").replace(/  +/g, " ") // avoiding special char and double space
    );
  };

  const onSearchSubmit = () => {
    setLoading(true);
    setTotalFoundArticles(0);
    let start_date = new Date();
    let end_date = new Date(moment().add(1, "days").format("YYYY-MM-DD"));
    if (selectedValue === "from_to") {
      start_date = startDate;
      end_date = endDate;
    } else if (selectedValue === "thirty_days") {
      start_date = new Date(moment().subtract(30, "days").format("YYYY-MM-DD"));
      end_date = new Date();
    }
    set_selected_startDate(moment(start_date).format("Do MMM, YYYY"));
    set_selected_endDate(moment(end_date).format("Do MMM, YYYY"));
    let data = {
      search: searchQuery,
      target: activeTab,
      size: 10,
      start: currentArticleCount,
      edition_date_from: start_date,
      edition_date_to: end_date,
    };
    if (!allEditions) {
      data.edition_code = props.defaultInitialEditionName;
    }
    if (sort !== "relevancy") {
      data.sort = sort;
    }
    if (data.search && data.target && props.defaultInitialEditionName)
      if (searchQuery.length > 1) {
        axios
          .post(
            // `http://localhost:5002/api/v1/epaper/search`,
            `${process.env.REACT_APP_SUBSCRIPTION_URL}/epaper/search`,
            data,
            {
              headers: {
                token: JSCookie.get("andre"),
                "Content-Type": "application/json",
              },
            }
          )
          .then((response) => {
            if (response.data.hits.found > 0) {
              let array = [...posts, ...response.data.hits.hit];
              for (let i = 0; i < array.length; i++) {
                let element = array[i];

                for (let i = 0; i < publicationJson.length; i++) {
                  const ele = publicationJson[i];
                  let edn = ele.editions.filter(
                    (v) => v.editionid == element.fields.edition_code
                  );
                  if (edn.length > 0) {
                    element.fields.publication = { ...ele, index: i };
                    element.fields.edition = {
                      ...edn[0],
                      index: ele.editions.findIndex(
                        (v) => v.editionid == element.fields.edition_code
                      ),
                    };
                  }
                }
              }
              setPosts(array);
              setCurrentArticleCount(array.length);
              setTotalFoundArticles(response.data.hits.found);
              setLastSearch({ ...data, selectedValue });
            }
            setTimeout(() => {
              setLoading(false);
            }, 500);
          })
          .catch((err) => {
            console.log("err: ", err);
            searchError.current = err.response?.data;
            setTimeout(() => {
              searchError.current = "";
            }, 1200);
          });
      }
  };

  const onLastSearchClick = () => {
    let last_search = { ...lastSearch };
    setSearchQuery(lastSearch.search);
    setSelectedValue(lastSearch.selectedValue);
    setLastSearch({});
  };

  const searchError = useRef("");

  const [size, setSize] = useState([0, 0]);
  useEffect(() => {
    if (!JSCookie.get("espubindex") || !JSCookie.get("esedindex")) {
      history.push("/eisamay/home");
    }
    window.addEventListener("resize", () => {
      setSize([window.innerWidth, window.innerHeight]);
    });
    return () => {
      window.removeEventListener("resize", () => {
        setSize([window.innerWidth, window.innerHeight]);
      });
    };
  }, []);

  const currentArticle = useRef("");
  const currentArticleImage = useRef("");
  const currentDate = useRef("");
  useEffect(() => {
    if (!JSCookie.get("espubindex") || !JSCookie.get("esedindex")) {
      history.push("/eisamay/home");
    }
    JSCookie.remove("fromArchive");
  }, []);
  const replace = (str) => {
    if (str) {
      return str
        .split("`")
        .join("₹")
        .split("<br>`<br>")
        .join("₹")
        .split("<br/>`<br/>")
        .join("₹")
        .split("<br/>₹<br/>")
        .join("₹")
        .split("<br>`")
        .join("₹")
        .split("`<br>")
        .join("₹")
        .split("`")
        .join("₹")
        .split("<br>¤<br>")
        .join("€")
        .split("<br>¤")
        .join("€")
        .split("¤<br>")
        .join("€")
        .split("¤")
        .join("€")
        ?.split("<br>")
        ?.join("<br/>")
        .split("&lt;")
        .join("<")
        .split("&gt;")
        .join(">");
    } else {
      return "";
    }
  };
  return (
    <div>
      <Tooltip title="Search">
        <div
          className="px-0 cursor-pointer"
          aria-controls="simple-menu"
          aria-haspopup="true"
          onClick={toggleModal}
        >
          {props.fromArchive ? (
            <div className="px-2 d-flex justify-content-between align-items-center">
              <Search className="icon-18" />
            </div>
          ) : (
            <>
              <Search color={props.themeColor} />
              <div className="desktop-icon-name small desktop-only">SEARCH</div>
            </>
          )}
        </div>
      </Tooltip>
      <ReactModal
        onRequestClose={() => setIsOpen(false)}
        shouldCloseOnOverlayClick={true}
        shouldCloseOnEsc={true}
        className="mymodalSearch"
        isOpen={isOpen}
        overlayClassName="myoverlay-search"
      >
        <Row className="justify-content-center">
          <Col xs="12" sm="12" md="12" lg="12" xl="12" className="">
            <input
              onChange={onChangeInput}
              type="search"
              id="form1"
              value={searchQuery}
              placeholder="SEARCH HERE"
              className="form-control form-control-dsearch input-search"
              aria-label="Search"
            />
          </Col>
          <Col xs="6" sm="6" md="6" lg="6" xl="6" className="pr-0 pl-3">
            <FormControlLabel
              value="TODAY’S PAPER"
              className="mr-0"
              control={
                <Radio
                  checked={selectedValue === "today"}
                  onChange={handleChange}
                  value="today"
                  color="primary"
                  size="medium"
                  name="radio-button-demo"
                  inputProps={{ "aria-label": "today" }}
                />
              }
            />
            <span style={{ pointerEvents: "none" }} className="">
              Today's Paper{" "}
            </span>
          </Col>
          <Col xs="6" sm="6" md="6" lg="6" xl="6" className="pr-0 pl-3">
            <FormControlLabel
              value=" ARCHIVES"
              className="mr-0"
              control={
                <Radio
                  checked={selectedValue === "thirty_days"}
                  onChange={handleChange}
                  value="thirty_days"
                  name="radio-button-demo"
                  size="medium"
                  inputProps={{ "aria-label": "thirty_days" }}
                />
              }
            />
            <span style={{ pointerEvents: "none" }} className="">
              Last 30 Days
            </span>
          </Col>
        </Row>
        <Row className="" style={{ alignItems: "center" }}>
          <Col xs="7" sm="7" md="6" className="pr-0 ">
            <Row>
              <Col xs="3" sm="2" md="2" className="pr-0">
                <FormControlLabel
                  value=" ARCHIVES"
                  className="mr-0 mb-0"
                  control={
                    <Radio
                      checked={selectedValue === "from_to"}
                      onChange={handleChange}
                      value="from_to"
                      name="radio-button-demo"
                      size="medium"
                      inputProps={{ "aria-label": "from_to" }}
                    />
                  }
                />
              </Col>
              <Col xs="8" className="d-flex align-items-center p-0">
                <div>
                  <span style={{ pointerEvents: "none" }} className="pr-1">
                    From
                  </span>
                  <DatePicker
                    dateFormat="dd/MM/yyyy"
                    checked={selectedValue === "from_to"}
                    width="medium"
                    className="datePickerSearch"
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                    selectsStart
                    startDate={startDate}
                    endDate={endDate}
                  />
                </div>
              </Col>
            </Row>
          </Col>
          <Col xs="5" sm="5" md="6" className="px-0 ">
            <span className="pr-3">To</span>
            <DatePicker
              dateFormat="dd/MM/yyyy"
              className="datePickerSearch"
              selected={endDate}
              onChange={(date) => setEndDate(date)}
              selectsEnd
              startDate={startDate}
              endDate={endDate}
              minDate={startDate}
            />
          </Col>
        </Row>
        {/* for all editions checkbox */}
        <Row className="" style={{ alignItems: "center" }}>
          <Col xs="5" sm="5" md="6" className="pr-0 pl-3">
            <FormControlLabel
              value="All Editions"
              className="mr-0"
              control={
                <Checkbox
                  checked={allEditions}
                  onChange={handleCheckboxChange}
                  inputProps={{ "aria-label": "primary checkbox" }}
                  color="primary"
                  size="medium"
                />
              }
            />
            <span style={{ pointerEvents: "none" }} className="">
              All Editions
            </span>
            {/* <Checkbox
              color="default"
              checked={allEditions}
              onChange={handleCheckboxChange}
            />{" "}
            <span className="pr-3">All Editions</span> */}
          </Col>
        </Row>
        <Row>
          <Col sm="12" md="12" lg="12" xl="12">
            <Nav
              className="justify-content-center"
              tabs
              style={{ border: "none" }}
            >
              <NavItem className="cursor-pointer ">
                <NavLink
                  style={{
                    border: "none",
                    borderRadius: "0px",
                    backgroundColor: "#f8f9fa",
                  }}
                  className={classnames({
                    active: activeTab === "article",
                  })}
                  onClick={() => {
                    toggle("article");
                  }}
                >
                  Articles
                </NavLink>
              </NavItem>
              <NavItem className="cursor-pointer mx-2">
                <NavLink
                  className="bg-light"
                  style={{
                    border: "none",
                    borderRadius: "0px",
                    backgroundColor: "#f8f9fa",
                  }}
                  className={classnames({
                    active: activeTab === "Photographs",
                  })}
                  onClick={() => {
                    toggle("Photographs");
                  }}
                >
                  Images
                </NavLink>
              </NavItem>
              <NavItem className="cursor-pointer">
                <NavLink
                  style={{
                    border: "none",
                    borderRadius: "0px",
                    backgroundColor: "#f8f9fa",
                  }}
                  className={classnames({
                    active: activeTab === "Advertisement",
                  })}
                  onClick={() => {
                    toggle("Advertisement");
                  }}
                >
                  Advertisements
                </NavLink>
              </NavItem>
            </Nav>
          </Col>
        </Row>
        {searchError.current && (
          <Row>
            <p>{searchError.current}</p>
          </Row>
        )}
        <hr style={{ borderTop: "1px solid #c5bebe" }} />

        <Row className="pt-2 justofy-content-center">
          {/* <Col
            xs="4"
            sm="4"
            md="4"
            lg="4"
            xl="4"
            onClick={() => {
              onSearchSubmit();
              toggleModal();
            }}
            className="px-0"
            style={{ display: "flex", justifyContent: "end" }}
          >
            <Button className="btn-search" onClick={() => onLastSearchClick()}>Last Search</Button>
          </Col> */}
          <Col
            xs="6"
            sm="6"
            md="6"
            lg="6"
            xl="6"
            onClick={() => {
              onSearchSubmit();
              toggleModal();
              openSearchResult();
            }}
            className="pr-3"
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            {
              <Button
                disabled={searchQuery.length > 1 ? false : true}
                className="btn-search"
                onClick={() => {
                  if (searchQuery && searchQuery.length > 0) {
                    searchError.current = "";
                    openSearchResult();
                  } else {
                    searchError.current = "Enter search text";
                  }
                }}
                style={{
                  cursor: searchQuery.length > 1 ? "pointer" : "not-allowed",
                }}
              >
                OK
              </Button>
            }
          </Col>
          <Col
            xs="6"
            sm="6"
            md="6"
            lg="6"
            xl="6"
            className="pl-3"
            onClick={toggleModal}
          >
            <Button className="" color="danger" style={{ minWidth: "106px" }}>
              Cancel
            </Button>
          </Col>
        </Row>
      </ReactModal>
      <ReactModal
        onRequestClose={() => {
          setPosts([]);
          setTotalFoundArticles(0);
          setCurrentArticleCount(0);
          setIsOpenSearchResult(false);
        }}
        shouldCloseOnOverlayClick={true}
        shouldCloseOnEsc={true}
        isOpen={isOpenSearchResult}
        // contentLabel="My dialog"
        className={desktopView ? "mymodal-search-result" : "mymodal-mob"}
        overlayClassName={
          desktopView ? "myoverlay-search-card" : "myoverlay-mob"
        }
      >
        <Row className="text-center mt-2">
          <Col
            xs="5"
            sm="5"
            md="4"
            lg="4"
            xl="4"
            className="date-search-result px-4 pt-3"
          >
            {selected_startDate}
            <span className="mx-1"> to </span>
            {selected_endDate}
          </Col>
          {!desktopView && (
            <Col
              xs="5"
              sm="5"
              className="px-0 pt-3 date-search-result justify-content-center"
            >
              <span>
                Sort By:
                <span
                  onClick={() => {
                    if (sort !== "relevancy") {
                      setTotalFoundArticles(0);
                      setCurrentArticleCount(0);
                      setPosts([]);
                      selectedSort("relevancy");
                    }
                  }}
                  style={{
                    cursor: "pointer",
                    color: sort === "relevancy" ? "var(--primary)" : "",
                  }}
                >
                  Relevancy
                </span>
                <span
                  onClick={() => {
                    if (sort !== "latest") {
                      setTotalFoundArticles(0);
                      setCurrentArticleCount(0);
                      setPosts([]);
                      selectedSort("latest");
                    }
                  }}
                  style={{
                    cursor: "pointer",
                    color: sort === "latest" ? "var(--primary)" : "",
                  }}
                  className="mx-1"
                >
                  Newest
                </span>
                <span
                  onClick={() => {
                    if (sort !== "older") {
                      setTotalFoundArticles(0);
                      setCurrentArticleCount(0);
                      setPosts([]);
                      selectedSort("older");
                    }
                  }}
                  style={{
                    cursor: "pointer",
                    color: sort === "older" ? "var(--primary)" : "",
                  }}
                >
                  Oldest
                </span>
              </span>
            </Col>
          )}
          {!desktopView && (
            <Col xs="2" sm="2" className=" pr-2 pb-0 pt-3 cursor-pointer">
              <XCircle
                className=""
                onClick={() => {
                  setPosts([]);
                  setTotalFoundArticles(0);
                  setCurrentArticleCount(0);
                  setIsOpenSearchResult(false);
                }}
                fontSize="large"
              />
            </Col>
          )}
          <Col xs="12" sm="12" md="4" lg="4" xl="4" className=" px-0">
            <Nav
              className="justify-content-center"
              tabs
              style={{ border: "none" }}
            >
              <NavItem className="cursor-pointer ">
                <NavLink
                  style={{
                    border: "none",
                    borderRadius: "0px",
                    backgroundColor: "#f8f9fa",
                  }}
                  className={classnames({
                    active: activeTab === "article",
                  })}
                  onClick={() => {
                    toggle("article");
                  }}
                >
                  Articles
                </NavLink>
              </NavItem>
              <NavItem className="cursor-pointer mx-2">
                <NavLink
                  className="bg-light"
                  style={{
                    border: "none",
                    borderRadius: "0px",
                    backgroundColor: "#f8f9fa",
                  }}
                  className={classnames({
                    active: activeTab === "Photographs",
                  })}
                  onClick={() => {
                    toggle("Photographs");
                  }}
                >
                  Images
                </NavLink>
              </NavItem>
              <NavItem className="cursor-pointer">
                <NavLink
                  style={{
                    border: "none",
                    borderRadius: "0px",
                    backgroundColor: "#f8f9fa",
                  }}
                  className={classnames({
                    active: activeTab === "Advertisement",
                  })}
                  onClick={() => {
                    toggle("Advertisement");
                  }}
                >
                  Ads
                </NavLink>
              </NavItem>
            </Nav>
          </Col>
          {desktopView && (
            <Col
              xs="6"
              sm="6"
              md="3"
              lg="3"
              xl="3"
              className="px-0 pt-2 text-right"
            >
              <span style={{ fontSize: "smaller" }}>
                Sort By:
                <span
                  onClick={() => {
                    if (sort !== "relevancy") {
                      setTotalFoundArticles(0);
                      setCurrentArticleCount(0);
                      setPosts([]);
                      selectedSort("relevancy");
                    }
                  }}
                  style={{
                    cursor: "pointer",
                    color: sort === "relevancy" ? "var(--primary)" : "",
                  }}
                  className="ml-2"
                >
                  Relevancy
                </span>
                <span
                  onClick={() => {
                    if (sort !== "latest") {
                      setTotalFoundArticles(0);
                      setCurrentArticleCount(0);
                      setPosts([]);
                      selectedSort("latest");
                    }
                  }}
                  style={{
                    cursor: "pointer",
                    color: sort === "latest" ? "var(--primary)" : "",
                  }}
                  className="mx-2"
                >
                  Newest
                </span>
                <span
                  onClick={() => {
                    if (sort !== "older") {
                      setTotalFoundArticles(0);
                      setCurrentArticleCount(0);
                      setPosts([]);
                      selectedSort("older");
                    }
                  }}
                  style={{
                    cursor: "pointer",
                    color: sort === "older" ? "var(--primary)" : "",
                  }}
                >
                  Oldest
                </span>
              </span>
            </Col>
          )}
          {desktopView && (
            <Col
              md="1"
              lg="1"
              xl="1"
              className="justify-content-end pb-0 pt-2 cursor-pointer"
            >
              <XCircle
                className=""
                onClick={() => {
                  setPosts([]);
                  setTotalFoundArticles(0);
                  setCurrentArticleCount(0);
                  setIsOpenSearchResult(false);
                }}
                fontSize="large"
              />
            </Col>
          )}
        </Row>
        <hr className="horizontal-line-search" />

        <TabContent
          activeTab={activeTab}
          className="pl-3 pt-3"
          style={{ height: "90%", overflowY: "scroll", overflowX: "hidden" }}
        >
          <TabPane tabId="article">
            {loading ? (
              <Spinner className="loaderArticleCard" />
            ) : posts && posts.length > 0 ? (
              posts.map((ele, id) => {
                let array = [];
                let grey_array = [];
                let score = Math.round(ele?.fields?._score / 2);
                for (let i = 0; i < score; i++) {
                  array.push(i + 1);
                }
                for (let i = 0; i < 5 - score; i++) {
                  grey_array.push(i + 1);
                }
                let article_id = ele?.fields?.articlename;

                let article_name = ele?.fields?.articlename.split("_");
                article_name.pop();
                article_name.pop();
                // const image_url = `${s3Source}/PublicationData/${props?.initialPublication?.publicationcode}/${props?.defaultInitialEditionName}/${article_name[2]}/${article_name[1]}/${article_name[0]}/PageThumb/${article_id}.jpg`;
                const image_url = `${s3Source}/PublicationData/${ele?.fields?.publication?.publicationcode}/${ele?.fields?.edition?.editionid}/${article_name[2]}/${article_name[1]}/${article_name[0]}/PageThumb/${article_name[0]}_${article_name[1]}_${article_name[2]}_${article_name[3]}_${ele?.fields?.edition?.editionid}.jpg`;
                let pgname = props?.dayIndexData?.findIndex(
                  (v) =>
                    v.PageName ===
                    article_name.join("_") +
                      "_" +
                      ele?.fields?.edition?.editionid
                );
                let pageName =
                  ele?.fields?.articlename.split("_").slice(0, 4).join(`_`) +
                  `_${ele?.fields?.edition?.editionid}`;
                return (
                  <Row
                    className="mb-2 search-container ml-2"
                    key={ele?.id + id}
                  >
                    <Col
                      xs="4"
                      sm="4"
                      md="4"
                      lg="4"
                      xl="4"
                      style={{ display: "contents", cursor: "pointer" }}
                    >
                      <CardImg
                        className="article-card-search"
                        top
                        src={image_url}
                        alt={"image_" + image_url.slice(2, 6)}
                        style={{
                          objectFit: "cover",
                          width: "auto",
                          height: "160px",
                          maxHeight: "150px",
                        }}
                        onClick={async () => {
                          await JSCookie.set("fromArchive", true);
                          await setPublication(ele?.fields?.publication);
                          await setEdition(ele?.fields?.edition);
                          await setDateArray(
                            ele?.fields?.articlename
                              .split("_")
                              .slice(0, 3)
                              .reverse()
                          );
                          setActiveTabName("article");

                          await axios
                            .get(
                              `${s3Source}/PublicationData/${
                                ele?.fields?.publication.publicationcode
                              }/${
                                ele?.fields?.edition.editionid
                              }/${ele?.fields?.articlename
                                .split("_")
                                .slice(0, 3)
                                .reverse()
                                .join("/")}/DayIndex/${ele?.fields?.articlename
                                .split("_")
                                .slice(0, 3)
                                .join("_")}_${
                                ele?.fields?.edition.editionid
                              }.json?v=${new Date().getTime()}`
                            )
                            .then(async (dayIndexRes) => {
                              let filteredPage =
                                dayIndexRes.data.DayIndex.findIndex(
                                  (v) => v.PageName === pageName
                                );
                              if (filteredPage !== -1)
                                setCurrentPage({
                                  ...dayIndexRes.data.DayIndex[filteredPage],
                                  pageNumber: parseInt(filteredPage) + 1,
                                });
                            });

                          currentArticle.current = ele?.fields?.articlename;
                          let dateSplit = ele?.fields?.articlename.split("_");
                          currentDate.current = dateSplit.slice(0, 3).reverse();
                          // props.handleDate(dateSplit.slice(0, 3).join("-"));
                          currentArticleImage.current = image_url;
                          setSearchValue({
                            search: searchQuery,
                            from: "article",
                          });
                          setIsOpenArticleModal(true);
                        }}
                      />
                    </Col>
                    <Col
                      // xs="12"
                      // sm="12"
                      // md="12"
                      // lg="12"
                      // xl="12"
                      className="ml-3 mt-2 px-0  article-search-card fff ff4"
                    >
                      <h6
                        className="mb-0 mt-0 font-weight-bold"
                        dangerouslySetInnerHTML={{
                          __html: replace(ele?.fields?.articletitle),
                        }}
                        onClick={async () => {
                          await JSCookie.set("fromArchive", true);
                          await setPublication(ele?.fields?.publication);
                          await setEdition(ele?.fields?.edition);
                          await setDateArray(
                            ele?.fields?.articlename
                              .split("_")
                              .slice(0, 3)
                              .reverse()
                          );
                          setActiveTabName("article");

                          await axios
                            .get(
                              `${s3Source}/PublicationData/${
                                ele?.fields?.publication.publicationcode
                              }/${
                                ele?.fields?.edition.editionid
                              }/${ele?.fields?.articlename
                                .split("_")
                                .slice(0, 3)
                                .reverse()
                                .join("/")}/DayIndex/${ele?.fields?.articlename
                                .split("_")
                                .slice(0, 3)
                                .join("_")}_${
                                ele?.fields?.edition.editionid
                              }.json?v=${new Date().getTime()}`
                            )
                            .then(async (dayIndexRes) => {
                              let filteredPage =
                                dayIndexRes.data.DayIndex.findIndex(
                                  (v) => v.PageName === pageName
                                );
                              if (filteredPage !== -1)
                                setCurrentPage({
                                  ...dayIndexRes.data.DayIndex[filteredPage],
                                  pageNumber: parseInt(filteredPage) + 1,
                                });
                            });

                          currentArticle.current = ele?.fields?.articlename;
                          let dateSplit = ele?.fields?.articlename.split("_");
                          currentDate.current = dateSplit.slice(0, 3).reverse();
                          // props.handleDate(dateSplit.slice(0, 3).join("-"));
                          currentArticleImage.current = image_url;
                          setSearchValue({
                            search: searchQuery,
                            from: "article",
                          });
                          setIsOpenArticleModal(true);
                        }}
                      />

                      <Row className="align-items-center pl-3">
                        <p className="mb-0 mr-2">
                          {moment(ele?.fields?.edition_date).format(
                            "DD/MM/YYYY"
                          )}{" "}
                        </p>{" "}
                        {array &&
                          array.length > 0 &&
                          array.map((item, id) => {
                            return (
                              <div key={`array-${id}`} className="rel1"></div>
                            );
                          })}
                        {grey_array &&
                          grey_array.length > 0 &&
                          grey_array.length < 5 &&
                          grey_array.map((item, id) => {
                            return (
                              <div
                                className="rel1"
                                key={"grey_array" + id}
                                style={{ backgroundColor: "grey" }}
                              ></div>
                            );
                          })}
                      </Row>

                      {ele?.fields?.articlebody && (
                        <p
                          className="mb-0"
                          style={{ lineHeight: "normal" }}
                          dangerouslySetInnerHTML={{
                            __html:
                              replace(ele?.fields?.articlebody)
                                ?.slice(0, 250)
                                .substring(0, 50) + "...",
                          }}
                        />
                      )}

                      <span>
                        {/* <b>By: </b>
                        {ele?.fields?.articleauthor?.substring(0, 10)} */}
                        {/* <br /> */}
                        <b>Edition: </b>
                        {ele?.fields?.publication?.publicationname} -{" "}
                        {ele?.fields?.edition?.editionname}
                        {/* <b className="ml-2">Section: </b>  // not having pagetitle
                        {ele?.fields?.pagetitle} */}
                        <b className="ml-2">Page: </b>{" "}
                        <span
                          style={{
                            color: "var(--primary)",
                            cursor: "pointer",
                          }}
                          onClick={async () => {
                            await JSCookie.set(
                              "espubindex",
                              ele?.fields?.publication.index,
                              { expires: 3 }
                            );
                            await JSCookie.set(
                              "esedindex",
                              ele?.fields?.edition.index,
                              { expires: 3 }
                            );
                            await JSCookie.set(
                              "eseddate",
                              ele?.fields?.articlename
                                ?.split("_")
                                ?.slice(0, 3)
                                .join("-")
                            );
                            await JSCookie.set("fromArchive", true);
                            await getPublicationsData(false, pageName);
                            setIsOpen(false);
                            setPosts([]);
                            setTotalFoundArticles(0);
                            setCurrentArticleCount(0);
                            setIsOpenSearchResult(false);

                            history.push(
                              `/${ele?.fields?.publication?.publicationname
                                ?.split(" ")
                                .join("-")
                                .toLowerCase()}/${ele?.fields?.edition?.editionname
                                ?.split(" ")
                                .join("-")
                                .toLowerCase()}`
                            );
                          }}
                        >
                          {/* {selectedValue === "today" ? (
                            <>{pgname + 1}</>
                          ) : ( */}
                          {Number(ele?.fields?.articlename.split("_")[3])}
                          {/* </> */}
                          {/* )} */}
                        </span>
                      </span>
                    </Col>
                  </Row>
                );
              })
            ) : (
              <div className="d-flex justify-content-center my-5">
                <h5>No results found</h5>
              </div>
            )}
            {totalFoundArticles > posts.length && (
              <div
                onClick={async () => {
                  await setCurrentArticleCount(posts.length);
                  onSearchSubmit();
                }}
                style={{ cursor: "pointer" }}
                className=" toi-more-anchor "
              >
                More Results ...
              </div>
            )}
          </TabPane>
          <TabPane tabId="Photographs">
            {loading ? (
              <Spinner className="loaderArticleCard" />
            ) : posts && posts.length > 0 ? (
              <Row className="justify-content-center">
                {posts.map((ele, id) => {
                  let array = [];
                  let grey_array = [];
                  let score = Math.round(ele?.fields?._score / 2);
                  for (let i = 0; i < score; i++) {
                    array.push(i + 1);
                  }
                  for (let i = 0; i < 5 - score; i++) {
                    grey_array.push(i + 1);
                  }

                  let article_name = ele?.fields?.articlename.split("_");

                  article_name.pop();
                  article_name.pop();
                  const image_url = `${s3Source}/PublicationData/${ele.fields?.publication?.publicationcode}/${ele?.fields?.edition?.editionid}/${article_name[2]}/${article_name[1]}/${article_name[0]}/Photographs/${article_name[3]}/${ele?.fields?.articlename}.jpg`;
                  const article_link = `${s3Source}/PublicationData/${
                    ele.fields?.publication?.publicationcode
                  }/${ele?.fields?.edition?.editionid}/${article_name[2]}/${
                    article_name[1]
                  }/${article_name[0]}/PageThumb/${
                    article_name.join("_") +
                    "_" +
                    ele?.fields?.edition?.editionid
                  }.jpg`;

                  let articleName = [...article_name];
                  articleName.pop();
                  let pgname = props?.dayIndexData?.findIndex(
                    (v) =>
                      v.PageName ===
                      articleName.join("_") +
                        "_" +
                        ele?.fields?.edition?.editionid
                  );
                  let pageName =
                    ele?.fields?.articlename.split("_").slice(0, 4).join(`_`) +
                    `_${ele?.fields?.edition?.editionid}`;
                  return (
                    <Col
                      key={ele?.id + id}
                      onClick={async () => {
                        await JSCookie.set("fromArchive", true);
                        await setPublication(ele?.fields?.publication);
                        await setEdition(ele?.fields?.edition);
                        await setDateArray(
                          ele?.fields?.articlename
                            .split("_")
                            .slice(0, 3)
                            .reverse()
                        );
                        await setImageUrl(image_url.replace(".jpg", ".json"));

                        await axios
                          .get(
                            `${s3Source}/PublicationData/${
                              ele?.fields?.publication.publicationcode
                            }/${
                              ele?.fields?.edition.editionid
                            }/${ele?.fields?.articlename
                              .split("_")
                              .slice(0, 3)
                              .reverse()
                              .join("/")}/DayIndex/${ele?.fields?.articlename
                              .split("_")
                              .slice(0, 3)
                              .join("_")}_${
                              ele?.fields?.edition.editionid
                            }.json?v=${new Date().getTime()}`
                          )
                          .then(async (dayIndexRes) => {
                            let filteredPage =
                              dayIndexRes.data.DayIndex.findIndex(
                                (v) => v.PageName === pageName
                              );
                            if (filteredPage !== -1)
                              await setCurrentPage({
                                ...dayIndexRes.data.DayIndex[filteredPage],
                                pageNumber: parseInt(filteredPage) + 1,
                              });
                          });

                        let dateSplit = ele?.fields?.articlename.split("_");
                        currentDate.current = dateSplit.slice(0, 3).reverse();

                        currentArticle.current =
                          article_name.join("_") +
                          "_" +
                          ele?.fields?.edition?.editionid;
                        currentArticleImage.current = article_link;
                        setSearchValue({
                          search: searchQuery,
                          from: "photographs",
                        });
                        setIsOpenArticleModal(true);
                      }}
                      xs="4"
                      sm="4"
                      md="4"
                      lg="3"
                      xl="3"
                      style={{
                        cursor: "pointer",
                        height: "160px",
                        maxWidth: "120px",
                        overflow: "hidden",
                      }}
                      className="border my-2 mx-3 d-flex justify-content-center p-0"
                    >
                      <CardImg
                        src={image_url ?? ""}
                        onLoad={() => image_url}
                        alt={ele?.fields?.articletitle}
                        id={ele?.fields?.articletitle}
                        style={{
                          overflow: "hidden",
                          // maxHeight: "150px",
                          objectFit: "cover",
                          objectPosition: "center top",
                          // height: "160px",
                          // maxWidth: "120px",
                        }}
                      />
                    </Col>
                  );
                })}
              </Row>
            ) : (
              <div className="d-flex justify-content-center my-5">
                <h5>No results found</h5>
              </div>
            )}
          </TabPane>
          <TabPane tabId="Advertisement">
            {loading ? (
              <Spinner className="loaderArticleCard" />
            ) : posts && posts.length > 0 ? (
              <Row className="justify-content-center">
                {posts.map((ele, id) => {
                  let array = [];
                  let grey_array = [];
                  let score = Math.round(ele?.fields?._score / 2);
                  for (let i = 0; i < score; i++) {
                    array.push(i + 1);
                  }
                  for (let i = 0; i < 5 - score; i++) {
                    grey_array.push(i + 1);
                  }

                  let article_name = ele?.fields?.articlename.split("_");
                  article_name.pop();
                  article_name.pop();
                  // article_name.pop();
                  const image_url = `${s3Source}/PublicationData/${ele?.fields?.publication?.publicationcode}/${ele?.fields?.edition?.editionid}/${article_name[2]}/${article_name[1]}/${article_name[0]}/Advertisement/${article_name[3]}/${ele?.fields?.articlename}.jpg`;
                  const article_link = `${s3Source}/PublicationData/${
                    ele?.fields?.publication?.publicationcode
                  }/${ele?.fields?.edition?.editionid}/${article_name[2]}/${
                    article_name[1]
                  }/${article_name[0]}/PageThumb/${
                    article_name.join("_") +
                    "_" +
                    ele?.fields?.edition?.editionid
                  }.jpg`;

                  let articleName = [...article_name];
                  articleName.pop();
                  let pgname = props?.dayIndexData?.findIndex(
                    (v) =>
                      v.PageName ===
                      articleName.join("_") +
                        "_" +
                        ele?.fields?.edition?.editionid
                  );
                  let pageName =
                    ele?.fields?.articlename.split("_").slice(0, 4).join(`_`) +
                    `_${ele?.fields?.edition?.editionid}`;
                  return (
                    <Col
                      key={ele?.id + id}
                      xs="4"
                      sm="4"
                      md="4"
                      lg="3"
                      xl="3"
                      style={{
                        cursor: "pointer",
                        height: "160px",
                        maxWidth: "120px",
                        overflow: "hidden",
                      }}
                      className="border my-2 mx-3 d-flex justify-content-center p-0"
                      onClick={async () => {
                        await JSCookie.set("fromArchive", true);
                        await setPublication(ele?.fields?.publication);
                        await setEdition(ele?.fields?.edition);
                        await setDateArray(
                          ele?.fields?.articlename
                            .split("_")
                            .slice(0, 3)
                            .reverse()
                        );
                        await axios
                          .get(
                            `${s3Source}/PublicationData/${
                              ele?.fields?.publication.publicationcode
                            }/${
                              ele?.fields?.edition.editionid
                            }/${ele?.fields?.articlename
                              .split("_")
                              .slice(0, 3)
                              .reverse()
                              .join("/")}/DayIndex/${ele?.fields?.articlename
                              .split("_")
                              .slice(0, 3)
                              .join("_")}_${
                              ele?.fields?.edition.editionid
                            }.json?v=${new Date().getTime()}`
                          )
                          .then(async (dayIndexRes) => {
                            let filteredPage =
                              dayIndexRes.data.DayIndex.findIndex(
                                (v) => v.PageName === pageName
                              );
                            if (filteredPage !== -1)
                              await setCurrentPage({
                                ...dayIndexRes.data.DayIndex[filteredPage],
                                pageNumber: parseInt(filteredPage) + 1,
                              });
                          });
                        await setAdvertisement(ele?.fields?.articlename);

                        let dateSplit = ele?.fields?.articlename.split("_");
                        await setImageUrl(image_url.replace(".jpg", ".json"));
                        currentDate.current = dateSplit.slice(0, 3).reverse();
                        currentArticle.current = ele?.fields?.articlename;
                        currentArticleImage.current = article_link;
                        setSearchValue({
                          search: searchQuery,
                          from: "advertisements",
                        });
                        setIsOpenArticleModal(true);
                      }}
                    >
                      <img
                        src={image_url ?? ""}
                        onLoad={() => image_url ?? ""}
                        alt={ele?.fields?.articletitle}
                        id={ele?.fields?.articletitle}
                        style={{
                          overflow: "hidden",
                          // maxHeight: "160px",
                          objectFit: "fill",
                          objectPosition: "center top",
                          // height: "150px",
                          // maxWidth: "120px",
                        }}
                      />
                    </Col>
                  );
                })}
              </Row>
            ) : (
              <div className="d-flex justify-content-center my-5">
                <h5>No results found</h5>
              </div>
            )}
          </TabPane>
        </TabContent>
      </ReactModal>
      {isOpenArticleModal && (
        <ArticleModal
          updateDayIndex={(date) => {
            props.handleDate(date);
          }}
          searchValue={{ ...searchValue, articleName: currentArticle.current }}
          themeColor={props?.themeColor}
          initialPublication={publication}
          defaultInitialEditionName={edition}
          dateArray={currentDate.current ?? props.dateArray}
          currentPage={currentPage}
          currentArticle={currentArticle.current}
          setCurrentArticle={() => {
            currentArticle.current = "";
          }}
          propsArticles={currentPage?.Articles ?? []}
          advertisement={advertisement}
          pageClick={() => {
            props.pageClick(
              currentArticleImage?.current,
              currentPage?.pageNumber - 1
            );
            setIsOpenSearchResult(false);
          }}
          dayIndexData={props.dayIndexData}
          setAd={() => {
            setAdvertisement("");
            let modal = document.getElementById("react-modal-desktop");
            modal.scrollTo({
              top: 0,
              behavior: "smooth",
            });
          }}
          mostShared={props.mostShared}
          closeModals={() => {
            setIsOpen(false);
            setPosts([]);
            setTotalFoundArticles(0);
            setCurrentArticleCount(0);
            setIsOpenSearchResult(false);
          }}
        />
      )}
    </div>
  );
}
