import React, { useEffect } from "react";

const Jsso = () => {
  useEffect(() => {
    localStorage.setItem("is_sso_login", "from_comp"); //using this to login in private window
    // to login through private window
    const ticketId = window?.location?.href?.split("ticketId=")[1];
    localStorage.setItem("temp_ticketId", ticketId); //using this to login in private window
    localStorage.setItem("login_via_sso", true);

    // window.onunload = refreshParent();
    // function refreshParent() {
    window.opener.location.reload();
    // window.close();
    window.opener = null;
    window.open("", "_self");
    window.close();
    // }
  }, []);

  return <React.Fragment></React.Fragment>;
};

export default Jsso;
