import React, { useState, useContext } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { AppContext } from "../contexts/AppContext";
import OutsideClickHandler from "react-outside-click-handler";
import { X } from "react-feather";
const SidebarLink = styled(Link)`
  display: flex;
  color: #363636;
  justify-content: space-between;
  align-items: center;
  padding: 20px 14px;
  list-style: none;
  height: 40px;
  text-decoration: none;
  font-size: 15px;
  // line-height: 2rem;
  font-style: normal;
  font-stretch: normal;
  text-overflow: ellipsis;
  font-weight: normal;
  font-family: arial;
  &:hover {
    // background-color: #e5e5e5;
    // border-radius: 2px;
    cursor: pointer;
    color: black;
    text-decoration: none;
    font-weight: bold;
  }
  &:focus {
    // background-color: #e5e5e5;
    // border-radius: 2px;
    cursor: pointer;
    color: black;
    text-decoration: none;
    font-weight: bold;
  }
`;

const SidebarLabel = styled.span`
  margin-left: 0px;
  font-family: arial;
  line-height: initial;
`;

const DropdownLink = styled(Link)`
  height: auto;
  min-height: 40px;
  padding-left: 1rem;
  margin: 0px 2px 0px 16px;
  display: flex;
  background-color: white;
  color: black;
  align-items: center;
  text-decoration: none;
  color: black;
  font-size: 16px;
  border-left: 2.5px solid #0000003b;
  &:hover {
    background: #e7e5e5;
    cursor: pointer;
    color: black;
    text-decoration: none;
    border-radius: 5px;
    font-weight: bold;
  }
`;

const SubmenuNav = ({
  item,
  pageClick,
  props,
  defaultInitialEditionName,
  pageNumber,
  hideSidebar,
}) => {
  // global state
  const {
    initialPublication,
    dateArray,
    isRegional,
    s3Source,
    initialEdition,
  } = useContext(AppContext);
  const [subnav, setSubnav] = useState(false);

  const showSubnav = () => setSubnav(!subnav);
  let path = `${s3Source}/PublicationData/${initialPublication.publicationcode}/${initialEdition.editionname}/${dateArray[0]}/${dateArray[1]}/${dateArray[2]}/PageThumb/${item.pageName}.jpg`;
  return (
    <>
      <SidebarLink
        onClick={() => {
          if (!isRegional) {
            item.subNav && showSubnav();
          }
          pageClick(path, pageNumber);

          if (isRegional) {
            setTimeout(() => hideSidebar(), 1000);
          }
        }}
      >
        <div>
          {item.icon}
          <SidebarLabel>{`Page :${pageNumber + 1}`}</SidebarLabel>
        </div>
        <div>
          {item.subNav && subnav
            ? item.iconOpened
            : item.subNav
            ? item.iconClosed
            : null}
        </div>
      </SidebarLink>

      {subnav &&
        item?.subNav?.map((subItem, index) => {
          let subTitle = subItem.title?.split("<br>")?.join("");
          subTitle = subTitle?.split("...")?.join("");
          return (
            <OutsideClickHandler
              onOutsideClick={() => {
                // hideSidebar();
              }}
            >
              <DropdownLink
                style={{}}
                onClick={() => {
                  pageClick(path, pageNumber, subItem.name);
                  hideSidebar();
                }}
                // to={subItem.path ?? "/"}
                key={index}
              >
                {subItem.icon}
                <div style={{ marginBottom: "0.5rem" }}>
                  <SidebarLabel
                  // onClick={() => {
                  //   hideSidebar();
                  // }}
                  >
                    {subTitle}
                  </SidebarLabel>
                </div>
              </DropdownLink>
            </OutsideClickHandler>
          );
        })}
    </>
  );
};

export default SubmenuNav;
