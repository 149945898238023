import React, { useContext, useMemo, useState, useEffect, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import { Card, Row, Col, CardImg, Modal } from "reactstrap";
import OutsideClickHandler from "react-outside-click-handler";
import { Home } from "react-feather";
import { Link } from "react-router-dom";
import { isIOS } from "react-device-detect";
import clsx from "clsx";
import app_img from "../assets/icons/Icons-15.png";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import "./desktopbottombar.css";
import "../pages/thumbnail.css";
import "../components/sidebar.css";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import PublicationEdition from "./PublicationEdition";
import "../components/sidebar.css";
import HighlightOffOutlinedIcon from "@material-ui/icons/HighlightOffOutlined";
import Tooltip from "@material-ui/core/Tooltip";
import JSCookie from "js-cookie";
import { Maximize } from "react-feather";

import {
  ArrowLeftCircle,
  ArrowRightCircle,
  Bookmark,
  Grid,
  XCircle,
  ZoomIn,
  ZoomOut,
} from "react-feather";
import Fab from "@material-ui/core/Fab";
import jsonp from "jsonp";
import axios from "axios";
import { useHistory } from "react-router-dom";
// context
import { AppContext } from "../contexts/AppContext";
import { HistoryOutlined } from "@material-ui/icons";
import { homeEvent, pageEvents, zoomEvents } from "../functions/cleverTap";

const useStyles = makeStyles((theme) => ({
  text: {
    // padding: theme.spacing(2, 2, 0),
  },

  list: {
    marginBottom: theme.spacing(2),
  },
  subheader: {
    backgroundColor: theme.palette.background.paper,
  },
  appBar: {
    top: "auto",
    bottom: 20,
    color: "white",
    maxHeight: "46px",
    borderRadius: "10px ",
    backgroundColor: "#000000d9 !important",
    right: "24.5vw",
    width: "74vw",
    zIndex: "10",
    padding: "0rem 0.1rem 0rem 1rem",
  },
  fullWidth: {
    right: 66.9,
    width: "91.4vw",
  },
  guttersPadding: {
    paddingLeft: "0px !important",
  },
  //mobBottomBar
  mobAppBar: {
    top: "auto",
    bottom: 0,
    zIndex: 1,
    paddingLeft: "",
    justifyContent: "center",
    height: "3rem",
    marginRight: "0px",
  },

  grow: {
    flexGrow: 1,
  },
  fabButton: {
    position: "absolute",
    zIndex: 1,
    left: 0,
    right: 0,
    margin: "0 auto",
  },
}));

export default function BottomBar(props) {
  const history = useHistory();
  const classes = useStyles();

  // global state
  const {
    isPopularDrawerOpen,
    setWidth,
    refactorHighlight,
    fit2page,
    setWidthPage2,
    refactorHighlightPage2,
    setShowLoader,
    setFit2page,
    s3Source,
    currentPage,
    dayIndexData,
    dateArray,
    pageClick,
    themeColor,
    setLogoAndTheme,
    editionData,
    handlePublication,
    handleEdition,
    publicationsData,
    initialPublication,
    initialEdition,
    setZoom,
    setCheckHeight,
    filteredDate,
    validateCustomer,
  } = useContext(AppContext);
  const [platform, setPlatform] = useState("web");
  const [photographSizeMob, setPhotographSizeMob] = useState({
    width: 100,
    height: "auto",
  });
  const photographRefMob = useRef(1);
  const [photographSize, setPhotographSize] = useState({
    width: 100,
    height: "auto",
  });
  const photographRef = useRef(1);
  const [isOpenMobThumb, setIsOpenMobThumb] = React.useState(false);
  const [isOpen, setIsOpen] = React.useState(false);
  function toggleModalThumbnail() {
    setIsOpen(!isOpen);
  }
  useEffect(() => {
    const platform = window.innerWidth > 760 ? "web" : "mobile";
    setPlatform(platform);
  }, []);
  function toggleModal() {
    setIsOpenMobThumb(!isOpenMobThumb);
  }
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const zoomInAndOut = (zoomBy) => {
    setWidth((currState) => {
      const newWidth = currState + zoomBy;
      refactorHighlight(newWidth, currState);
      return newWidth;
    });
    if (fit2page) {
      setWidthPage2((currState) => {
        const newWidth2 = currState + zoomBy;
        refactorHighlightPage2(newWidth2, currState);
        return newWidth2;
      });
    }
  };

  const twoPageImage1Name = useMemo(() => {
    if (fit2page && currentPage?.pageNumber === 1) {
      return currentPage?.PageName;
    } else if (fit2page) {
      return dayIndexData?.[
        currentPage?.pageNumber + (currentPage?.pageNumber - 3)
      ]?.PageName;
    }
  }, [dayIndexData, currentPage, fit2page]);

  const twoPageImage2Name = useMemo(() => {
    if (fit2page && currentPage?.pageNumber === 1) {
      return dayIndexData?.[currentPage?.pageNumber]?.PageName;
    } else if (fit2page) {
      return dayIndexData?.[
        currentPage?.pageNumber + (currentPage?.pageNumber - 3) + 1
      ]?.PageName;
    }
  }, [dayIndexData, currentPage, fit2page]);

  const fullScreen = (param) => {
    if (param === "fscr") {
      var elem = document.documentElement;
      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      } else if (elem.webkitRequestFullscreen) {
        /* Safari */
        elem.webkitRequestFullscreen();
      } else if (elem.msRequestFullscreen) {
        /* IE11 */
        elem.msRequestFullscreen();
      }
    }
  };
  // useEffect(() => {
  //   jsonp(
  //     `https://jsso.indiatimes.com/sso/crossdomain/getTicket?channel=${
  //       process.env.REACT_APP_CHANNEL_SSO_NAME
  //     }&platform=${platform}&version=v1&t=${Date.now()}`,

  //     null,
  //     function async(err, data) {
  //       if (err) {
  //         console.error(err.message);
  //       } else {
  //         if (data.status && data.ticketId) {
  //           let ssoTicketId = data.ticketId;
  //           const url = `https://jsso.indiatimes.com/sso/crossdomain/v1validateTicket?channel=${process.env.REACT_APP_CHANNEL_SSO_NAME}&ticketId=${ssoTicketId}`;
  //           jsonp(url, null, function (err, data) {
  //             if (err) {
  //               console.log(err);
  //             } else {
  //               validateCustomer(ssoTicketId);
  //             }
  //           });
  //         }
  //       }
  //     }
  //   );
  // }, [currentPage.pageNumber]);

  const checkCookie = async () => {
    if (window.gtag && localStorage.getItem("webAnalytics") === "true") {
      // //publication
      // window.gtag("event", "page_view", {
      //   page_title: initialPublication?.publicationcode,
      //   page_location: window.location.origin,
      //   page_path: `/${initialPublication?.publicationname
      //     ?.toLowerCase()
      //     ?.split(" ")
      //     ?.join("-")}/${initialEdition?.editionname
      //     ?.toLowerCase()
      //     ?.split(" ")
      //     ?.join("-")}`,
      //   send_to: initialPublication?.trackingId,
      // });
      // //edition
      // window.gtag("event", "page_view", {
      //   page_title: initialEdition?.editionid,
      //   page_location: window.location.origin,
      //   page_path: `/${initialPublication?.publicationname
      //     ?.toLowerCase()
      //     ?.split(" ")
      //     ?.join("-")}/${initialEdition?.editionname
      //     ?.toLowerCase()
      //     ?.split(" ")
      //     ?.join("-")}`,
      //   send_to: initialEdition?.trackingId,
      // });
    }

    //if (window?.googletag?.pubads()) {
    // console.log("refreshing");
    //window?.googletag?.pubads()?.refresh();
    //}
    // ReactGA.pageview(window.location.href);
    // ga.('send', {
    //   hitType: 'pageview',
    //   page: window.location.pathname
    // });
  };
  const handleLogout = () => {
    window.location.reload();
    let customer_details = localStorage.getItem("customer_details");
    if (customer_details) customer_details = JSON.parse(customer_details);
    axios
      .delete(
        `${process.env.REACT_APP_SUBSCRIPTION_URL}/customer/logout/${customer_details?.id}`
      )
      .then((res) => {
        console.log("logged out");
      });
    let prev_localstorage = localStorage.getItem("active_day");
    localStorage.clear();
    localStorage.setItem("active_day", prev_localstorage);

    JSCookie.remove("token");
    jsonp(
      `https://jsso.indiatimes.com/sso/identity/profile/logout/external?channel=${process.env.REACT_APP_CHANNEL_SSO_NAME}`,
      null,
      function async(err, data) {
        if (err) {
          console.error(err.message);
        } else {
        }
      }
    );
  };
  return (
    <React.Fragment>
      <Row className="desktop-only ">
        <Col md="3"></Col>
        <Col md="6">
          <Row className="">
            <AppBar
              id="app-bar"
              className={clsx(classes.appBar, {
                [classes.fullWidth]: !isPopularDrawerOpen,
              })}
            >
              <Toolbar
                className="text-center flow-root"
                style={{ minHeight: "46px" }}
              >
                <IconButton
                  id="zoom-in"
                  onClick={() => {
                    zoomEvents("zoom", "zoom_in", "zoom", "bottom_navigation");
                    zoomInAndOut(100);
                  }}
                  color="inherit"
                  style={{ float: "left" }}
                >
                  <ZoomIn className="feather-75" />
                </IconButton>
                <IconButton color="inherit" style={{ float: "left" }}>
                  <div className="vertical"></div>
                </IconButton>
                <IconButton
                  id="zoomOut"
                  onClick={() => {
                    zoomInAndOut(-100);
                    zoomEvents("zoom", "zoom_out", "zoom", "bottom_navigation");
                  }}
                  style={{ float: "left" }}
                  color="inherit"
                >
                  <ZoomOut className="feather-75" />
                </IconButton>
                <IconButton
                  id="page-numbers"
                  edge="end"
                  color="inherit"
                  style={{
                    marginRight: "0.5rem",
                    width: "8rem",
                    overflow: "hidden",
                  }}
                >
                  <Tooltip
                    title={
                      currentPage?.PageTitle?.replace(
                        "Maharashtra Times",
                        "MT"
                      ) ?? "Page Title"
                    }
                  >
                    <b className="page-font-bottombar">
                      {currentPage?.PageTitle?.replace(
                        "Maharashtra Times",
                        "MT"
                      )}
                    </b>
                  </Tooltip>
                </IconButton>
                <IconButton
                  onClick={toggleModalThumbnail}
                  style={{ width: "4rem" }}
                >
                  <Fab
                    style={{ backgroundColor: themeColor }}
                    id="grid-view"
                    className="fabButton"
                  >
                    <Grid className="feather-75" />
                  </Fab>
                </IconButton>
                <IconButton
                  id="bookmark"
                  edge="end"
                  color="inherit"
                  style={{ marginLeft: "4.5rem", opacity: 0 }}
                >
                  <Bookmark className="feather-75" />
                </IconButton>
                {currentPage &&
                currentPage?.pageNumber < dayIndexData?.length &&
                twoPageImage1Name !== dayIndexData?.slice(-1)[0].PageName &&
                twoPageImage2Name !== dayIndexData?.slice(-1)[0].PageName ? (
                  <IconButton
                    id="next-button"
                    style={{ float: "right", width: "60px" }}
                    color="inherit"
                  >
                    <Tooltip title="Next Page">
                      <ArrowRightCircle
                        onClick={() => {
                          pageEvents(
                            "page_next",
                            "page_next",
                            "page_next",
                            "page_next"
                          );
                          checkCookie();
                          if (
                            currentPage?.pageNumber !== dayIndexData?.length
                          ) {
                            let filter = dayIndexData?.filter((v, i, a) => {
                              return i === currentPage?.pageNumber;
                            });
                            if (filter && filter.length > 0) {
                              let path = `${s3Source}/PublicationData/${initialPublication.publicationcode}/${initialEdition.editionid}/${dateArray[0]}/${dateArray[1]}/${dateArray[2]}/PageThumb/${filter[0].PageName}.jpg?v=${filteredDate?.Timestamp}`;
                              pageClick(path, currentPage?.pageNumber);
                            }
                          }
                          window.scrollTo({
                            top: 0,
                            behavior: "smooth",
                          });
                          fit2page && setShowLoader(true);
                          setTimeout(() => {
                            setShowLoader(false);
                          }, 1000);

                          setZoom(false);
                          setCheckHeight(false);
                          window?.epaperZoom?.resetTransform();
                        }}
                        className="feather-75 arrowRightDesk"
                      />
                    </Tooltip>
                  </IconButton>
                ) : (
                  ""
                )}
                {currentPage &&
                currentPage?.pageNumber !== 1 &&
                currentPage?.pageNumber !== dayIndexData?.length ? (
                  <IconButton
                    color="inherit"
                    style={{ float: "right", width: "30px" }}
                  >
                    <div className="vertical"></div>
                  </IconButton>
                ) : (
                  ""
                )}

                {currentPage && currentPage?.pageNumber > 1 ? (
                  <IconButton
                    id="previous-button"
                    style={{ float: "right", width: "60px" }}
                    color="inherit"
                  >
                    <Tooltip title="Previous Page">
                      <ArrowLeftCircle
                        onClick={() => {
                          pageEvents(
                            "page_previous",
                            "page_previous",
                            "page_previous",
                            "page_previous"
                          );
                          checkCookie();
                          if (currentPage?.pageNumber !== 1) {
                            let filter = dayIndexData?.filter((v, i, a) => {
                              return i === currentPage?.pageNumber - 2;
                            });
                            if (filter && filter.length > 0) {
                              let path = `${s3Source}/PublicationData/${initialPublication.publicationcode}/${initialEdition.editionid}/${dateArray[0]}/${dateArray[1]}/${dateArray[2]}/PageThumb/${filter[0].PageName}.jpg?v=${filteredDate?.Timestamp}`;
                              pageClick(path, currentPage?.pageNumber - 2);
                            }
                          }
                          window.scrollTo({
                            top: 0,
                            behavior: "smooth",
                          });
                          fit2page && setShowLoader(true);
                          setTimeout(() => {
                            setShowLoader(false);
                          }, 2000);
                          setZoom(false);
                          setCheckHeight(false);

                          window?.epaperZoom?.resetTransform();
                        }}
                        className="feather-75 arrowLeftDesk"
                      />
                    </Tooltip>
                  </IconButton>
                ) : (
                  ""
                )}
              </Toolbar>
            </AppBar>
          </Row>
        </Col>
        <Col md="3"></Col>
      </Row>
      {/* <OutsideClickHandler
        onOutsideClick={() => {
          setIsOpen(false);
        }}
      > */}
      <Modal
        isOpen={isOpen}
        toggle={toggleModalThumbnail}
        className="desktop-thumbnail"
        id="modal"
      >
        <div
          className="modal-content-desktop-thmbnl "
          style={{ backgroundColor: "#fff0" }}
        >
          <Row className="pt-5 space-thumb-desktop">
            <Col className="text-align-right text-white cursor-pointer pr-0 pt-3">
              <XCircle fontSize="large" onClick={() => setIsOpen(false)} />
            </Col>
            <Col md="12" xl="12" lg="12" sm="12">
              <Row className="">
                {dayIndexData &&
                  dayIndexData?.length > 0 &&
                  dayIndexData?.map((item, index) => {
                    let path = `${s3Source}/PublicationData/${initialPublication.publicationcode}/${initialEdition.editionid}/${dateArray[0]}/${dateArray[1]}/${dateArray[2]}/PageThumb/${item.PageName}.jpg`;
                    return (
                      <Col
                        className="px-1 pb-4 cursor-pointer "
                        key={index + "_" + item.PageName}
                        lg="1"
                        md="2"
                        sm="2"
                        xl="1"
                        onClick={() => {
                          setFit2page(false);
                          pageClick(path, index);
                          setZoom(false);
                          setCheckHeight(false);
                          // setTimeout(() => {
                          setIsOpen(false);
                          // }, 1200);
                        }}
                      >
                        <Card
                          style={{
                            // width: photographSize?.width
                            //   ? photographSize?.width + 3
                            //   : "auto",
                            minWidth: "-webkit-fill-available",
                          }}
                        >
                          <img
                            className="pages-card-img "
                            style={{
                              maxHeight:
                                isIOS && window.innerWidth < 1366
                                  ? "120px"
                                  : isIOS && window.innerWidth > 1365
                                  ? "140px"
                                  : "auto",
                              // width: photographSize?.width
                              //   ? photographSize?.width
                              //   : "auto",
                              height: photographSize?.height
                                ? photographSize?.height
                                : "auto",
                              minWidth: "-webkit-fill-available",
                            }}
                            onLoad={() => {
                              setPhotographSize({
                                width: photographRef?.current?.clientWidth,
                                height: photographRef?.current?.clientHeight,
                              });
                            }}
                            ref={photographRef}
                            src={path}
                            alt="pages"
                          />

                          <div
                            className="page-font-bottombar text-white text-center"
                            style={{
                              borderRadius: "0px 0px 5px 5px",
                              backgroundColor: themeColor,
                              padding: " 0px 4px",
                              minWidth: "-webkit-fill-available",
                            }}
                          >
                            {`Page: ${index + 1}`}
                          </div>
                        </Card>
                      </Col>
                    );
                  })}
              </Row>
            </Col>
          </Row>
        </div>
      </Modal>
      {/* </OutsideClickHandler> */}
      {/* //For mobile and small devices only //////// */}
      <div className="mob-only">
        <AppBar
          position="fixed"
          className={classes.mobAppBar}
          style={{
            backgroundColor: themeColor,
          }}
        >
          <Tabs
            TabIndicatorProps={{ style: { background: "rgb(255 255 255)" } }}
            value={value}
            onChange={handleChange}
            variant="fullWidth"
          >
            {currentPage && currentPage?.pageNumber > 1 ? (
              <Tab
                icon={
                  <ArrowBackIcon
                    id="previous-button"
                    onClick={() => {
                      pageEvents(
                        "page_previous",
                        "page_previous",
                        "page_previous",
                        "page_previous"
                      );
                      if (currentPage?.pageNumber !== 1) {
                        let filter = dayIndexData?.filter((v, i, a) => {
                          return i === currentPage?.pageNumber - 2;
                        });
                        if (filter && filter.length > 0) {
                          let path = `${s3Source}/PublicationData/${initialPublication.publicationcode}/${initialEdition.editionid}/${dateArray[0]}/${dateArray[1]}/${dateArray[2]}/PageThumb/${filter[0].PageName}.jpg?v=${filteredDate?.Timestamp}`;
                          pageClick(path, currentPage?.pageNumber - 2);
                        }
                      }
                      window.scrollTo({
                        top: 0,
                        behavior: "smooth",
                      });
                      setZoom(false);
                      setCheckHeight(false);

                      window?.epaperZoom?.resetTransform();
                    }}
                  />
                }
              />
            ) : (
              ""
            )}
            {/* <Link to="/home" style={{ textDecoration: "none", color: "white" }}> */}
            <Tab
              onClick={() => {
                homeEvent(
                  "home_button",
                  "home_button",
                  "home_button",
                  "header"
                );
                // history.push("/home");
                // setTimeout(() => {
                //   window.location.reload();
                // }, 500);
                window.location.assign(
                  `${window.location.origin}/eisamay/home`
                );
              }}
              icon={<Home />}
            />
            {/* </Link> */}
            <Tab
              className=""
              icon={
                <PublicationEdition
                  themeColor={themeColor}
                  setLogoAndTheme={setLogoAndTheme}
                  editionData={editionData}
                  handlePublication={handlePublication}
                  handleEdition={handleEdition}
                  publicationsData={publicationsData}
                />
              }
            />
            <Tab
              icon={<img src={app_img} height="20px" alt="pages menu" />}
              onClick={toggleModal}
            />

            {currentPage && currentPage?.pageNumber < dayIndexData?.length ? (
              <Tab
                icon={
                  <ArrowForwardIcon
                    id="next-button"
                    onClick={() => {
                      pageEvents(
                        "page_next",
                        "page_next",
                        "page_next",
                        "page_next"
                      );
                      if (currentPage?.pageNumber !== dayIndexData?.length) {
                        let filter = dayIndexData?.filter((v, i, a) => {
                          return i === currentPage?.pageNumber;
                        });

                        if (filter && filter.length > 0) {
                          let path = `${s3Source}/PublicationData/${initialPublication.publicationcode}/${initialEdition.editionid}/${dateArray[0]}/${dateArray[1]}/${dateArray[2]}/PageThumb/${filter[0].PageName}.jpg?v=${filteredDate?.Timestamp}`;
                          pageClick(path, currentPage?.pageNumber);
                        }
                      }
                      window.scrollTo({
                        top: 0,
                        behavior: "smooth",
                      });
                      setZoom(false);
                      setCheckHeight(false);

                      window?.epaperZoom?.resetTransform();
                    }}
                  />
                }
              />
            ) : (
              " "
            )}
          </Tabs>
        </AppBar>
      </div>
      <OutsideClickHandler
        onOutsideClick={() => {
          setIsOpenMobThumb(false);
        }}
      >
        <Modal isOpen={isOpenMobThumb} overlayClassName="myoverlay">
          <div
            className="modal-content pt-2 "
            style={{ backgroundColor: "#fff0" }}
          >
            <Row>
              <Col className="text-align-end text-white">
                <HighlightOffOutlinedIcon
                  fontSize="medium"
                  onClick={() => setIsOpenMobThumb(false)}
                />
              </Col>
            </Row>

            <Row className="px-3 pb-5">
              {dayIndexData &&
                dayIndexData?.length > 0 &&
                dayIndexData?.map((item, index) => {
                  let path = `${s3Source}/PublicationData/${initialPublication.publicationcode}/${initialEdition.editionid}/${dateArray[0]}/${dateArray[1]}/${dateArray[2]}/PageThumb/${item.PageName}.jpg?v=${filteredDate?.Timestamp}`;
                  return (
                    <Col
                      key={index}
                      className="pb-1 px-1"
                      sm="3"
                      xs="4"
                      onClick={() => {
                        pageClick(path, index);
                        setZoom(false);
                        setCheckHeight(false);
                        setTimeout(() => {
                          setIsOpenMobThumb(false);
                        }, 1200);
                      }}
                    >
                      <Card
                        className=""
                        style={{
                          width: photographSizeMob?.width
                            ? photographSizeMob?.width + 3
                            : "auto",
                          minWidth:
                            window.innerWidth > 412 && "-webkit-fill-available",
                        }}
                      >
                        <img
                          style={{
                            width: photographSizeMob?.width
                              ? photographSizeMob?.width
                              : "auto",
                            height: photographSizeMob?.height
                              ? photographSizeMob?.height
                              : "auto",
                            minWidth:
                              window.innerWidth > 412 &&
                              "-webkit-fill-available",
                          }}
                          onLoad={() => {
                            setPhotographSizeMob({
                              width: photographRefMob?.current?.clientWidth,
                              height: photographRefMob?.current?.clientHeight,
                            });
                          }}
                          ref={photographRefMob}
                          src={path}
                          alt="Card image cap2"
                        />
                        <div
                          className="page-font-bottombar text-white text-center"
                          style={{
                            borderRadius: "0px 0px 5px 5px",
                            backgroundColor: themeColor,
                            padding: " 0px 1px",
                            width: photographSizeMob?.width
                              ? photographSizeMob?.width
                              : "auto",
                            minWidth:
                              window.innerWidth > 412 &&
                              "-webkit-fill-available",
                          }}
                        >
                          {`Page: ${index + 1}`}
                        </div>
                      </Card>
                    </Col>
                  );
                })}
            </Row>
          </div>
        </Modal>
      </OutsideClickHandler>
    </React.Fragment>
  );
}
